.IssueCardWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  min-height: 200px;
  width: 95%;
  background-color: #e6e6e6;
  border-radius: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  transition: all ease 0.5s;

  &:hover {
    transform: scaleY(1.08) scaleX(1.08);
  }

  .IssueCardTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
    padding: 10px;
    font-size: 24px;
    font-weight: bold;
    border-bottom: 2px dotted #06AFC9;
  }

  .IssueCardContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 10px 30px 10px;

    .SeverityText {
      font-size: 18px;
      line-height: 1.25em;
      font-weight: normal;
      margin-top: 20px;
      margin-bottom: 15px;
      text-align: center;
    }

    .SeverityBar {
      display: inline-block;
      position: relative;
      width: 50%;
      height: 20px;
      border: 4px solid #ffffff;
      border-radius: 14px;
      background: rgb(38,255,0);
      background: linear-gradient(90deg, rgba(38,255,0,1) 0%, rgba(255,220,0,1) 13%, rgba(255,160,0,1) 40%, rgba(255,0,0,1) 70%, rgba(121,24,24,1) 96%);

      .ServityBarDot {
        position: absolute;
        width: 16px;
        height: 16px;
        padding: 0;
        margin: 2px;
        background-color: #ffffff;
        border-radius: 50%;
        box-shadow: 1px 1px 3px rgb(0 0 0 / 0.5);
        z-index: 801;
      }
    }

    .PerformanceEffectText {
      font-size: 18px;
      color: #333333;
      font-weight: bold;
      margin-top: 15px;
    }

    .PerformanceEffectValue {
      color: #C1272D;
      font-size: 40px;
      font-weight: bold;
    }

    .DetailButton {
      display: flex;
      align-items: center;
      height: 24px;
      line-height: 24px;
      background-color: #047F92;
      color: #ffffff;
      font-weight: bold;
      padding: 0 10px;
      border-radius: 12px;
      transition: all ease 0.3s;
      cursor: pointer;
      font-size: 12px;
      margin-top: 30px;

      span {
        font-size: 18px;
        margin-right: 3px;
      }
    }
  }
}