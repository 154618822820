.AccountSelection {
  display: grid;
  grid-template-columns: 32px 194px 32px;
  margin-top: 14px;
  z-index: 999;
}

.AvatarContainer {
  background-color: #06AFC9;
  border-radius: 50% 0 0 50%;
  height: 32px;
}

.Avatar {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  // vertical-align: top;
}

.Dropdown {
  background-color: #06AFC9;
  min-width: 180px;
  line-height: 32px;
  height: 32px;
  padding-left: 4px;
  width: 194px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 25px auto;
  position: relative;

  .CurrentAccount {
    font-weight: bolder;
    color: white;
    font-size: 14px;
    margin-left: 5px;
  }

  .WalletIconContainer {
    position: relative;
  }

  #Wallet {
    color: white;
  }

  .htWallet {
    &:before {
      font-family: 'ht-font';
      content: "\e91d";
    }
    font-size: 24px;
    line-height: 32px;
    display: inline-block;
    margin-right: 5px;
    position: relative;
    .VerifiedIconContainer { 
      bottom: 5px;
      right: -3px;
    }
  }

  .VerifiedIconContainer {
    background: radial-gradient(circle at center, white 0, white 55%, transparent 56%);
    line-height: 12px;
    color: #009245;
    font-size: 12px;
    position: absolute;
    right: -2px;
    bottom: 9px;
    


    .VerifiedIcon {
      &:before {
        font-family: 'ht-font';
        content: "\e902";
      }
      height: 12px;
      font-style: normal;
      text-transform: none;
      font-variant: normal;
      -webkit-font-smoothing: antialiased;
      display: block;
    }
  }


  .AccountList {
    position: absolute;
    top:32px;
    background-color: #06AFC9;
    width: 210px;
    left: -16px;
    color: #047F92;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.75);
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease;
    font-size: 14px;
    border-radius: 0 0 20px 20px;

    .SelectedAccountOption {
      color: white;
      padding-left: 20px;
      font-weight: bold;
      .htWallet {
        vertical-align: middle;
      }
      position: relative;
    }

    .SwitchAccount {
      padding-left: 20px;
    }
  }

  .AccountList.isOpen {
    max-height: 1000px;
    transition: all 0.3s ease;
  }

  .AccountOption {
    padding-left: 20px;
    transition: all 0.3s ease;
    cursor: pointer;
    color: #047F92;

    &:hover {
      transition: all 0.3s ease;
      color: white;
    }
    .htWallet {
      vertical-align: middle;
    }
    position: relative;
  }

  .DropdownOption {
    padding-left: 20px;
    cursor: pointer;
    color: #047F92;
    transition: all 0.3s ease;
    &:hover {
      color: white !important;
      transition: all 0.3s ease;
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    .htWalletSetting {
      font-size: 24px;
      line-height: 32px;
      display: inline-block;
      &:before {
        font-family: "ht-font";
        content: "\e91a"
      }
      vertical-align: middle;
      padding-right: 5px;
    }

    .htWalletAdd {
      font-size: 24px;
      line-height: 32px;
      display: inline-block;
      &:before {
        font-family: "ht-font";
        content: "\e91c"
      }
      vertical-align: middle;
      padding-right: 5px;
    }

    .htMoon {
      font-size: 24px;
      line-height: 32px;
      display: inline-block;
      &:before {
        font-family: "ht-font";
        content: "\e90e"
      }
      vertical-align: middle;
      padding-right: 5px;
    }

    .htSupport {
      font-size: 24px;
      line-height: 32px;
      display: inline-block;
      &:before {
        font-family: "ht-font";
        content: "\e91b"
      }
      vertical-align: middle;
      padding-right: 5px;
    }
  }
}

.OpenButton {
  background-color: #FFFFFF;
  width: 32px;
  height: 32px;
  text-align: center;
  border-radius: 0 6px 6px 0;
  display: inline-block;
  cursor: pointer;
  color: #047F92;
  transition: all 0.3s ease;

  &:hover {
    background-color: #06AFC9;
    color: white;
  }

  .Triangle {
    &:before {
      font-family: 'ht-font' !important;
      content: "\e90a";
    }
    position: relative;
    top: 4px;

    font-size: 20px;
    display: inline-block;
    transition: all 0.3s ease;
  }
}

.CloseButton {
  background-color: #033C45;
  width: 32px;
  height: 32px;
  text-align: center;
  border-radius: 0 6px 6px 0;
  display: inline-block;
  cursor: pointer;
  color: #FFFFFF;
  transition: all 0.3s ease;


  .TriangleFlip {
    &:before {
      font-family: 'ht-font' !important;
      content: "\e90a";
    }

    &:hover {
      color: #06AFC9;
    }
    position: relative;
    transform: scaleY(-1);

    font-size: 20px;
    display: inline-block;
    transition: all 0.3s ease;
  }
}

.SeparateLine {
  border-width: 0 0 1px 0;
  border-style: dotted;
  border-color: #047F92;
  margin: 0;
  padding: 0;
}

.Logout {
  margin-bottom: 10px;
  padding-left: 20px;
  cursor: pointer;
  color: #047F92;
  &:hover {
    color: white;
  }

  .htLogout {
    font-size: 24px;
    line-height: 32px;
    display: inline-block;
    &:before {
      font-family: "ht-font";
      content: "\e90d"
    }
    vertical-align: middle;
    margin-right: 5px;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 16px;
  line-height: 16px;
  left: 50px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked {
      + .slider {
        background-color: #033C45;
      }
    }
    &:focus {
      + .slider {
        box-shadow: 0 0 1px rgba(0 0 0 / 0.5);
      }
    }
    &:checked + .slider:before {
      -webkit-transform: translateX(12px);
      -ms-transform: translateX(12px);
      transform: translateX(12px);
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    &:before {
      position: absolute;
      content: "";
      height: 12px;
      width: 12px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
  }
  .round {
    border-radius: 8px;
    &:before {
      border-radius: 50%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .AccountSelection {
    display: none;
  }
}