.Modal {
  text-align: left;
  height: 600px;
  width: 340px;
  flex-direction: column;
  border-radius: 20px;
  background-color: #ABBEC2;
  margin-bottom: -20px;
  padding-bottom: 20px;

  .ModalHeader {
    background-color: #047F92;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    width: 100%;
    height: 60px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  
    .Logo {
      display:inline-block;
      font-size: 40px;
      padding:10px 40px 0 10px;
      color:#FFFFFF;
      box-sizing: border-box;
      height: 60px;
      line-height: 1;
    }
    
    .htLogo {
      &:before {
        content: "\e921";
        font-family: 'ht-font' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        color: white;
        padding-left: 10px;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      font-size: 28px;
    }

    .title {
      color: #FFFFFF;
      font-weight: 600;
    }

    .CrossIconButton {
      width: 32px;
      height: 32px;
      background-color: #FFFFFF;
      border-radius: 6px;
      text-align: center;
      display: inline-block;
      position: absolute;
      right: 20px;
    
      color: #047F92;
      transition: all 0.3s ease-in-out;
      &:hover {
        color: white;
        background-color: #06AFC9;
      }
    
      .CancelIcon {
        font-size: 28px;
    
        line-height: 32px;
        cursor: pointer;
      }
    }
  }

  .SpinnerContainer {
    margin: auto;

    .SignInIndicator {
      color: #047F92;
      margin-bottom: 20px;
      text-align: center;
    }
  }

  .LoginForm {
    width: 80%;
    margin: auto;
  }

  .InputWrapper {
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 8px;
    margin-top: 15px;

    .InputLabel {
      font-size: 10px;
      color: #999999;
      padding-top: 2px;
    }

    .InputTemplate {
      outline: none;
      border: none;
      padding: 8.25px 0;
      font-size: 18px;
      width: 100%;
    }

    .ActualInput {
      font-size: 16px;
      outline: none;
      border: none;
      padding-bottom: 4px;
      display: block;
      margin-top: -2px;
      width: 100%;
    }
  }

  .Block {
    height: 30px;
    width: 100%;
    color: #ED1C24;
    font-size: 14px;
    padding-top: 8px;
    box-sizing: border-box;
  }

  .LoginButton {
    background-color: #047F92;
    color: white;
    font-weight: bold;
    text-align: center;
    padding: 10px 0;
    border-radius: 8px;
    margin-top: 15px;
    cursor: pointer;
    transition: 0.3s all ease;
    user-select: none;

    &:hover, &:active {
      background-color: #06AFC9;
      transition: 0.3s all ease;
    }

    .LoginIcon {
      font-size: 24px;
      vertical-align: middle;
      position: relative;
      bottom: 2px;
    }

    &.DisabledButton {
      background-color: #999999;
    }
  }

  .NoteToRegister {
    margin: 10px 0;

    .RegisterUnderline {
      text-decoration-skip-ink: none;
      cursor: pointer;
      font-weight: bold;
      margin-left: 5px;
      color: #047F92;
    }
  }
}

