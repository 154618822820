.TradeMedicInfoModal {
  padding: 20px;
  width: 80vw;
  text-align: left;

  .ModalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .IssueName {
      font-size: 14px;
      color: #033c45;
      font-weight: bold;
    }

    .CrossIconButton {
      width: 32px;
      height: 32px;
      background-color: #FFFFFF;
      border-radius: 6px;
      text-align: center;
      display: inline-block;
  
      color: #047F92;
      transition: all 0.3s ease-in-out;
      &:hover {
        color: white;
        background-color: #06AFC9;
      }
  
      .CancelIcon {
        font-size: 28px;
  
        line-height: 32px;
        cursor: pointer;
      }
    }
  }

  .ModalContent {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 10px;
    margin-top: 20px;
    width: 100%;

    .ContentLeft {
      display: flex;
      flex-direction: column;
      
      .Subtitle {
        margin-bottom: 20px;
      }

      .DetailText {
        margin-bottom: 20px;
      }

      .FurtherInformation {
        display: grid;
        grid-template-columns: 50% 50%;
        color: blue;
      }
    }

    .ContentRight {
      grid-column: 2/3;
      width: calc(90% - 10px);

      .linkBtn {
        background-color: #D9D0BF;
        color: #047F92;
        width: fit-content;
        border-radius: 6px;
        padding: 2px 5px;
        margin-top: 15px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          background-color: #06AFC9;
          color: white;
        }

        #link {
          text-decoration: none;

          &:visited {
            color: inherit;
          }

          #linkContent {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}

.TradeMediGeneralInfo {
  padding: 20px;
  width: 80vw;
  text-align: left;

  .ModalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .IssueName {
      font-size: 14px;
      color: #033c45;
      font-weight: bold;
    }

    .CrossIconButton {
      width: 32px;
      height: 32px;
      background-color: #FFFFFF;
      border-radius: 6px;
      text-align: center;
      display: inline-block;
  
      color: #047F92;
      transition: all 0.3s ease-in-out;
      &:hover {
        color: white;
        background-color: #06AFC9;
      }
  
      .CancelIcon {
        font-size: 28px;
  
        line-height: 32px;
        cursor: pointer;
      }
    }
  }

  .ModalContent {
    margin-top: 20px;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .TradeMedicInfoModal {
    padding: 10px;
  
    .ModalHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .IssueName {
        font-size: 14px;
        color: #033c45;
        font-weight: bold;
      }
    }
  
    .ModalContent {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      width: 100%;
  
      .ContentLeft {
        display: flex;
        flex-direction: column;
        
        .Subtitle {
          margin-bottom: 5px;
          font-size: 14 px;
        }
  
        .DetailText {
          margin-bottom: 10px;
        }
      }
  
      .ContentRight {
        width: 80%;
      }
    }
  }
}