.SpinnerContainer {
  margin: auto;
  text-align: center;
  margin-top: 50px;

  .SignInIndicator {
    color: #047F92;
    margin-bottom: 20px;
    text-align: center;
  }
}

.LoginForm {
  width: 85%;
  margin: auto;
}

.InputWrapper {
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
  margin-top: 15px;

  .InputLabel {
    font-size: 10px;
    color: #999999;
    padding-top: 2px;
    text-align: left;
  }

  .InputTemplate {
    outline: none;
    border: none;
    padding: 9.25px 0;
    font-size: 16px;
    width: 100%;
  }

  .ActualInput {
    font-size: 16px;
    outline: none;
    border: none;
    padding-bottom: 4px;
    display: block;
    margin-top: -2px;
    width: calc(100% - 10px);
    padding-left: 0;
  }
}

.Block {
  width: 100%;
  color: #ED1C24;
  font-size: 14px;
  padding-top: 8px;
  box-sizing: border-box;
}

.LoginButton {
  background-color: #047F92;
  color: white;
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s all ease;
  user-select: none;

  &:hover, &:active {
    background-color: #06AFC9;
    transition: 0.3s all ease;
  }

  .LoginIcon {
    font-size: 24px;
    vertical-align: middle;
    position: relative;
    bottom: 2px;
  }

  &.DisabledButton {
    background-color: #999999;
  }
}

.NoteToRegister {
  margin: 10px 0;

  .RegisterUnderline {
    text-decoration-skip-ink: none;
    cursor: pointer;
    font-weight: bold;
    margin-left: 5px;
    color: #047F92;
  }
}

.ForgetPasswordText {
  color: #047F92;
  margin-top: 10px;
  cursor: pointer;
  width: fit-content;
  padding-bottom: 15px;
}
