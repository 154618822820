
.hover-menu-enter {
  opacity: 0;
  transform: translateX(-500px);
}

.hover-menu-enter-active {
  opacity: 1;
  transition: all 600ms ease-out;
  transform: translateX(0);
}

.hover-menu-exit {
  margin-left: 0;
}

.hover-menu-exit-active {
  margin-left: -250;
  transition: all 600ms;
}