.FaqSectionWrapper {
  margin-top: 5px;

  .SectionHeader {
    cursor: pointer;
    background-color: #FCC306;
    min-height: 40px;
    padding: 0 10px;
    font-size: 14px;
    color: #000000;
    font-weight: bold;
    transition: all ease 0.3s;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background-color:#CFA20A;
    }

    .HeaderLeft {
      display: flex;
      align-items: center;

      span {
        font-size: 20px;
        margin-right: 5px;
        font-weight: normal;
      }
    }
  }

  .SectionContent {
    background-color: rgb(239, 239, 239);
    padding: 10px;
  }
}