.htWallet {
  &:before {
    content: "\e91d";
    font-family: 'ht-font';
  }
}

.htPieChart {
  &:before {
    content: "\e918";
    font-family: 'ht-font';
  }
}

.htClusterChart {
  &:before {
    content: "\e917";
    font-family: 'ht-font';
  }
}

.htClipboard {
  &:before {
    content: "\e916";
    font-family: 'ht-font';
  }
}

.htStar {
  &:before {
    content: "\e910";
    font-family: 'ht-font';
  }
}

.htBarChart {
  &:before {
    content: "\e91f";
    font-family: 'ht-font';
  }
}

.htCoffee {
  &:before {
    content: "\e915";
    font-family: 'ht-font';
  }
}

.htTiming {
  &:before {
    content: "\e914";
    font-family: 'ht-font';
  }
}

.htAlert {
  &:before {
    content: "\e913";
    font-family: 'ht-font';
  }
}

.htChessCastle {
  &:before {
    content: "\e912";
    font-family: 'ht-font';
  }
}

.htCoins {
  &:before {
    content: "\e911";
    font-family: 'ht-font';
  }
}

.htDashboard {
  font-family: 'ht-font';
  &:before {
    content: "\e920"
  }
}

.htBell {
  font-family: 'ht-font';
  &:before {
    content: "\e91e";
  }
}

.htGlobe {
  font-family: 'ht-font';
  &:before {
    content: "\e90f";
  }
}

.htInfo {
  font-family: 'ht-font';
  &:before {
    content: "\e901";
  }
}

.htCross {
  font-family: 'ht-font';
  &:before {
    content: "\e92a";
  }
}

.htVideo {
  font-family: 'ht-font';
  &:before {
    content: "\e907";
  }
}

.htBackArrow {
  font-family: 'ht-font';
  &:before {
    content: "\e932";
  }
}

.htLoginLogout {
  font-family: 'ht-font';
  &:before {
    content: "\e90d";
  }
}  

.htUpChart {
  font-family: 'ht-font';
  &:before {
    content: "\e929";
  }
}

.htDownChart {
  font-family: 'ht-font';
  &:before {
    content: "\e928";
  }
}

.htMedal {
  font-family: 'ht-font';
  &:before {
    content: "\e908";
  }
}

.htNegChart {
  font-family: 'ht-font';
  &:before {
    content: "\e930";
  }
}

.htArrow {
  font-family: 'ht-font';
  &:before {
    content: "\e90b";
  }
}

.htLogo {
  font-family: 'ht-font';
  &:before {
    content: "\e921";
  }
}

.htFbIcon {
  font-family: 'ht-font';
  &:before {
    content: "\e906";
  }
}

.htTwitterIcon {
  font-family: 'ht-font';
  &:before {
    content: "\e905";
  }
}

.htLinkedInIcon {
  font-family: 'ht-font';
  &:before {
    content: "\e904";
  }
}

.htYtIcon {
  font-family: 'ht-font';
  &:before {
    content: "\e903";
  }
}

.htIgIcon {
  font-family: 'ht-font';
  &:before {
    content: "\e935";
  }
}

.htDiscord {
  font-family: 'ht-font';
  &:before {
    content: "\e947";
  }
}

.htEyeOpened {
  font-family: 'ht-font';
  &:before {
    content: "\e936";
  }
}

.htEyeClosed {
  font-family: 'ht-font';
  &:before {
    content: "\e939";
  }
}

.htTrash {
  font-family: 'ht-font';
  &:before {
    content: "\e92d";
  }
}

.htEnvelope {
  font-family: 'ht-font';
  &:before {
    content: "\e92e";
  }
}

.htSpeedometer {
  font-family: 'ht-font';
  &:before {
    content: "\e931";
  }
}

.htEnvelopeOpen {
  font-family: 'ht-font';
  &:before {
    content: "\e93d";
  }
}
    
.htLogin {
  font-family: 'ht-font';
  &:before {
    content: "\e93f";
  }
}

.htRegister {
  font-family: 'ht-font';
  &:before {
    content: "\e934";
  }
}

.htAdd {
  font-family: 'ht-font';
  &:before {
    content: "\e93b";
  }
}

.htUnmute {
  font-family: 'ht-font';
  &:before {
    content: "\e97e";
  }
}

.htMute {
  font-family: 'ht-font';
  &:before {
    content: "\e97d";
  }
}

.htShare {
  font-family: 'ht-font';
  &:before {
    content: "\e943";
  }
}

.htChain {
  font-family: 'ht-font';
  &:before {
    content: "\e937";
  }
}

.htAlertChart {
  font-family: 'ht-font';
  &:before {
    content: "\e92f";
  }
}

.htMedbox {
  font-family: 'ht-font';
  &:before {
    content: "\e97f";
  }
}

.htCheck {
  font-family: 'ht-font';
  &:before {
    content: "\e933";
  }
}

.htErrorTrade {
  font-family: 'ht-font';
  &:before {
    content: "\e987";
  }
}