.SelectionContainer {
  width: 80%;
  margin:  20px auto 40px auto;
  height: 100%;
}

.SectionTitle {
  font-size: 18px;
  font-weight: bold;
  color: #4D4D4D;
}

.SectionDescription {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 20px;
}

.Form {
  margin: auto;
  height: 100%;
}

.InfoRow {
  margin-top: 15px;

  .InfoLabel {
    font-size: 16px;
    margin-bottom: 5px;
    display: block;
    color: #4D4D4D;

    .InfoIcon {
      color: #047F92;
      font-size: 18px;
      margin-left: 3px;
      cursor: pointer;
    }
  }

  .InfoInput {
    width: 95%;
    height: 35px;
    border-radius: 5px;
    border: none;
    padding-left: 10px;
    font-size: 16px;
  }
}

.NextButton {
  background-color: #047F92;
  color: white;
  font-weight: bold;
  font-size: 18px;
  width: fit-content;
  padding: 8px 12px;
  border: 1px solid #047F92;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  top: 105px;
  float: right;

  &:hover, &:active {
    background-color: #06AFC9;
    transition: 0.3s all ease;
    border: 1px solid #06AFC9;
  }

  &.Disabled {
    background-color: #999999;
    border: 1px solid #999999;
  }
}