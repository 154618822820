.MobileAccountSelection {
  margin-top: 14px;
}

.AvatarContainer {
  height: 32px;
  text-align: center;
}

.Avatar {
  height: 32px;
  width: 32px;
  border-radius: 50%;
}
.MobileDropdown {
  display: relative;

  .Backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.75);
    // z-index: 9999;
  }
  
  .DropdownContent {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #06AFC9;
    border-radius: 20px 20px 0 0;
    display: flex;
    flex-direction: column;
    align-items: left;

    .CloseLine {
      width: 100px;
      border: 3px solid #033C45;
      border-radius: 5px;
      margin-top: 20px;
      margin-bottom: 20px;
    }


    .CurrentAccount {
      display: flex;
      align-items: center;
      gap: 15px;
      font-weight: bolder;
      color: white;
      font-size: 14px;
      margin-left: 20px;
  

      .AvatarContainer {
        width: 40px;
        height: 40px;

        .Avatar {
          width: 40px;
          height: 40px;
        }
      }
    }

    .SwitchAccount {
      display: flex;
      flex-direction: column;
      margin-left: 20px;

      .SelectedAccountOption {
        margin-left: 10px;
        display: flex;
        align-items: center;
        color: white;
        font-weight: bold;
        font-size: 14px;
      }

      .AccountOption {
        margin-left: 10px;
      }
    }

    .SeperatedLine {
      border: 1px dashed #047F92;
      width: 100%;
    }
  }
}

.DropdownOption {
  margin-left: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  color: #047F92;
}

.WalletIconContainer {
  position: relative;
}

#Wallet {
  color: white;
}

.htWallet {
  &:before {
    font-family: 'ht-font';
    content: "\e91d";
  }
  font-size: 24px;
  line-height: 32px;
  display: inline-block;
  margin-right: 5px;
  position: relative;

  .VerifiedIconContainer {
    bottom: 5px;
    right: -3px;
  }
}

.VerifiedIconContainer {
  background: radial-gradient(circle at center, white 0, white 55%, transparent 56%);
  line-height: 12px;
  color: #009245;
  font-size: 12px;
  position: absolute;
  right: 1px;
  bottom: 7px;
  

  .VerifiedIcon {
    &:before {
      font-family: 'ht-font';
      content: "\e902";
    }
    height: 12px;
    font-style: normal;
    text-transform: none;
    font-variant: normal;
    -webkit-font-smoothing: antialiased;
    display: block;
  }
}

.htWalletSetting {
  font-size: 24px;
  line-height: 32px;
  display: inline-block;
  &:before {
    font-family: "ht-font";
    content: "\e91a"
  }
  vertical-align: middle;
  padding-right: 5px;
}

.htWalletAdd {
  font-size: 24px;
  line-height: 32px;
  display: inline-block;
  &:before {
    font-family: "ht-font";
    content: "\e91c"
  }
  vertical-align: middle;
  padding-right: 5px;
}

.htMoon {
  font-size: 24px;
  line-height: 32px;
  display: inline-block;
  &:before {
    font-family: "ht-font";
    content: "\e90e"
  }
  vertical-align: middle;
  padding-right: 5px;
}

.htSupport {
  font-size: 24px;
  line-height: 32px;
  display: inline-block;
  &:before {
    font-family: "ht-font";
    content: "\e91b"
  }
  vertical-align: middle;
  padding-right: 5px;
}

.Logout {
  margin-bottom: 10px;
  padding-left: 20px;
  cursor: pointer;
  color: #047F92;
  &:hover {
    color: white;
  }

  .htLogout {
    font-size: 24px;
    line-height: 32px;
    display: inline-block;
    &:before {
      font-family: "ht-font";
      content: "\e90d"
    }
    vertical-align: middle;
    margin-right: 5px;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 16px;
  line-height: 16px;
  left: 10px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked {
      + .slider {
        background-color: #033C45;
      }
    }
    &:focus {
      + .slider {
        box-shadow: 0 0 1px rgba(0 0 0 / 0.5);
      }
    }
    &:checked + .slider:before {
      -webkit-transform: translateX(12px);
      -ms-transform: translateX(12px);
      transform: translateX(12px);
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    &:before {
      position: absolute;
      content: "";
      height: 12px;
      width: 12px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
  }
  .round {
    border-radius: 8px;
    &:before {
      border-radius: 50%;
    }
  }
}


@media only screen and (min-width: 769px) {
  .MobileAccountSelection {
    display: none;
  }
}