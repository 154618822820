@media only screen and (min-width: 606px) {
  .CommissionComparisonModal {
    width: 80vw;
    max-width: 1041px;
    height: auto;
  
    .CommissionComparisonModalHeader {
      padding: 20px 0 10px 20px;
      text-align: left;
      display: flex;
      flex-direction: column;    
    
      .title {
        font-size: 14px;
        font-weight: 700;
        color: #033C45;
      }
    
      .CrossIconButton {
        width: 32px;
        height: 32px;
        background-color: #FFFFFF;
        border-radius: 6px;
        text-align: center;
        position: absolute;
        right: 20px;
        font-size: 36px;
        line-height: 60px;
      
        color: #047F92;
        transition: all 0.3s ease-in-out;
        &:hover {
          color: white;
          background-color: #06AFC9;
        }
      
        .CancelIcon {
          font-size: 28px;
      
          line-height: 32px;
          cursor: pointer;
        }
      }
    }
  
    .CommissionComparisonModalBody {
      display: grid;
      width: 100%;
      height: 100%;
      grid-template-columns: 3fr 2fr;
  
      .CommissionComparisonModalChart {
        grid-column: 1/2;
        padding-left: 20px;
        padding-top: 10px;
        padding-right: 20px;
        padding-bottom: 10px;
      }
  
      .BrokerCheckboxes {
        display: flex;
        flex-direction: column;
  
        .CurrentBroker {
          display: flex;
          gap: 10px;
          border-bottom: solid 1px #06AFC9;
          color: #4D4D4D;
          padding-top: 20px;
          padding-bottom: 20px;
          align-items: center;
          margin-right: 15px;
        }
  
        .BrokerCheckbox {
          display: flex;
          gap: 10px;
          border-bottom: solid 1px #06AFC9;
          padding-top: 20px;
          padding-bottom: 20px;
          align-items: center;
          font-size: 14px;
          font-weight: 700;
          color:#4D4D4D;
          margin-right: 15px;
        }
  
        .btn {
          color: #047F92;
          text-align: center;
          text-decoration: none;
          display: inline-block;
          font-size: 12px;
          font-weight: 400;
          border: none;
          border-radius: 5px;
          height: 25px;
          margin-left: auto;
          margin-right: 20px;
          padding-left: 15px;
          padding-right: 15px;
          outline: none;
          cursor: pointer;

          &:hover, &:active {
            background-color: #333333;
            color: white;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 376px) {
  .CommissionComparisonModal {
    width: 95vw;
    height: auto;
  
    .CommissionComparisonModalHeader {
      padding: 20px 0 10px 20px;
      text-align: left;
      display: flex;
      flex-direction: column;    
    
      .title {
        font-size: 14px;
        font-weight: 700;
        color: #033C45;
      }
    
      .CrossIconButton {
        width: 32px;
        height: 32px;
        background-color: #FFFFFF;
        border-radius: 6px;
        text-align: center;
        position: absolute;
        right: 20px;
        font-size: 36px;
        line-height: 60px;
        margin-top: -10px;
      
        color: #047F92;
        transition: all 0.3s ease-in-out;
        &:hover {
          color: white;
          background-color: #06AFC9;
        }
      
        .CancelIcon {
          font-size: 28px;
      
          line-height: 32px;
          cursor: pointer;
        }
      }
    }

    .CommissionComparisonModalBody {
      width: 100%;
  
      .CommissionComparisonModalChart {
        grid-column: 1/2;
        padding-left: 20px;
        padding-top: 10px;
        padding-right: 20px;
      }
  
      .BrokerCheckboxes {
        display: flex;
        flex-direction: column;
  
        .CurrentBroker {
          display: flex;
          gap: 10px;
          border-bottom: solid 1px #06AFC9;
          color: #4D4D4D;
          padding-top: 20px;
          padding-bottom: 20px;
          align-items: center;
          margin-right: 15px;
          margin-left: 15px;
        }
  
        .BrokerCheckbox {
          display: flex;
          gap: 10px;
          border-bottom: solid 1px #06AFC9;
          padding-top: 20px;
          padding-bottom: 20px;
          align-items: center;
          font-size: 14px;
          font-weight: 700;
          color:#4D4D4D;
          margin-right: 15px;
          margin-left: 15px;
        }
  
        .btn {
          color: #047F92;
          text-align: center;
          text-decoration: none;
          display: inline-block;
          font-size: 12px;
          font-weight: 400;
          border: none;
          border-radius: 5px;
          height: 25px;
          margin-left: auto;
          margin-right: 20px;
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }
}