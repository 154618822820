.background {
  background-color: #033c45;
  width: calc(100% - 30px);
  padding: 0 15px;
}

.footerContainer {
  width: 100%;
  margin: 0 auto;
  color: #047F92;
  font-size: 12px;
  text-align: left;

  .footerTop {
    padding-top: 20px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;

    .footerCopyright {
      font-size: 12px;
      display: flex;
      align-items: center;

      .logo {
        font-size: 24px;
      }
    }

    .footerLinks {
      display: flex;
      margin-left: 10px;

      .linkBlock {
        border-left: solid 1px #047F92;
        padding: 0 10px;
        font-weight: bold;
        transition: all 0.3s ease;
        cursor: pointer;

        &:hover {
          color: white;
        }
      }
    }
  }

  .footerSocial {
    display: flex;
    padding-bottom: 15px;

    a {
      color: #047F92;
      font-size: 18px;
      vertical-align: middle;
      text-decoration: none;
      padding: 0 10px;
      transition: all 0.3s ease;

      &:hover {
        color: white;
      }
    }
  }

  .disclaimer {
    padding-bottom: 10px;
  }
}