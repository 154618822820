.Navbar {
  height: 60px;
  background-color: #047F92;
  text-align: left;
  display: grid;
  grid-template-columns: 90px auto 362px;
  grid-column: 1 / 3;
  grid-row: 1/ 2;
  position: fixed;
  width: 100%;
  z-index: 999;
}

.Logo {
  display:inline-block;
	font-size: 40px;
	padding:10px 40px 0 10px;
	color:#FFFFFF;
  box-sizing: border-box;
  height: 60px;
  line-height: 1;
}

.htLogo {
  &:before {
    content: "\e921";
    font-family: 'ht-font' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color: white;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  font-size: 40px;
}

.Links {
  display: inline-block;
  vertical-align: baseline;
}

.Link {
  display: inline-block;
  font-family: "Noto Sans";
  font-weight: bold;
  color: #06AFC9;
  font-size: 18px;
  line-height: 60px;
  margin-right: 20px;
}

.SelectedLink {
  display: inline-block;
  font-family: "Noto Sans";
  font-weight: bold;
  color: white;
  font-size: 18px;
  line-height: 60px;
  margin-right: 20px;
}

.RightMenu {
  display: grid;
  grid-template-columns: auto 42px 42px;
  margin-right: 20px;
}

.SearchButton {
  background-color: #FFFFFF;
  height: 32px;
  width: 32px;
  border-radius: 6px;
  padding-top: 6px;
  padding-left: 6px;
  box-sizing: border-box;
  position: relative;
  top: 14px;
  left: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #047F92;

  &:hover {
    background-color: #06AFC9;
    color: white;
  }

  .htSearch {
    content: "\e921";
    font-family: 'ht-font' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    font-size: 20px;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &:before {
      content: "\e90c";
    }
  }
}

.BellButton {
  background-color: #FFFFFF;
  height: 32px;
  width: 32px;
  border-radius: 6px;
  padding-top: 6px;
  padding-left: 6px;
  box-sizing: border-box;
  position: relative;
  top: 14px;
  left: 10px;
  cursor: pointer;
  line-height: 1;
  color: #047F92;
  transition: all 0.3s ease;

  &:hover {
    background-color: #06AFC9;
    color: white;
  }

  .htBell {
    content: "\e91e";
    font-family: 'ht-font' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;

    font-size: 20px;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &:before {
      content: "\e91e";
    }
  }

  .NotificationNumber {
    color: white;
    background-color: #C1272D;
    border-radius: 8px;
    padding: 0 8px;
    font-size: 10px;
    height: 16px;
    line-height: 16px;
    text-align: center;;
    width: fit-content;
    position: absolute;
    right: -12px;
    bottom: -8px;
  }
}

@media only screen and (max-width: 768px) {
  .Navbar {
    height: 60px;
    background-color: #047F92;
    text-align: left;
    display: grid;
    grid-template-columns: 60px auto 150px;
    grid-column: 1 / 3;
    grid-row: 1/ 2;
    position: fixed;
    width: 100%;
    z-index: 999;
  }

  .Logo {
    display:inline-block;
    font-size: 40px;
    padding: 10px 10px 0 10px;
    color:#FFFFFF;
    box-sizing: border-box;
    height: 60px;
    line-height: 1;
  }
  
  .RightMenu {
    display: grid;
    grid-template-columns: 42px 42px 42px;
    justify-content: center;
    margin-right: 20px;
  }
}