.TradeMedicContentWrapper {
  background-color: #000000;

  .TradeMedicContentHeader {
    background-image: url("../../assets/trade-medic-report-cover-pic-broker.png"), url("../../assets/trade-medic-report-cover-bg-broker.png");
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 300px;
    margin: 0 0 15px 0;
    padding: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .LogoBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 15px 10px 15px;
      margin: 0 0 10px 15px;
      background-color: #ffffff;
      border-radius: 0 0 20px 20px;
      height: 200px;
      box-shadow: 3px 3px 10px rgba(0,0,0,0.5);
      
      .Logo {
        height: 150px;
      }

      .PowerBy {
        display: flex;
        text-align: center;
        align-items: center;
        font-size: 11px;
        line-height: 0.8em;
        color: #047F92;
        padding: 20px 0 0 0;

        .HocTradeLogo {
          width: 80px;
        }
      }
    }

    .SessionBox {
      background-color: #000000;
      color: #FCC306;
      display: flex;
      font-size: 12px;
      flex-wrap: wrap;
      justify-content: space-between;
      position: absolute;
      right: 15px;
      bottom: 0;
      padding: 10px 10px 5px 10px;
      border-radius: 20px 20px 0 0;
      width: 250px;

      .SessionText {
        text-align: left;
        width: 50%;
        padding: 0 0 5px 0;
      }
    }
  }

  .TradeMedicMainContent {
    padding: 0 15px 15px 15px;

    .Disclaimer {
      display: block;
      color: #666666;
      font-size: 10px;
      line-height: 1.25em;
      text-align: left;
      padding-bottom: 20px;
    }

    .TradeMedicTableWrapper {
      background-color: #000000;
      text-align: left;
  
      .TableTitle {
        color: #000000;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #FCC306;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
  
        .TitleLeft {
          display: flex;
          align-items: center;
          font-weight: bold;
  
          span {
            font-size: 28px;
            margin-right: 5px;
          }
        }
  
        .InfoIconContainer {
          cursor: pointer;
          
          span {
            color: #000000;
            font-size: 18px;
          }
        }
      }
  
      .Top4IssuesWrapper {
        background-color: #666666;
        padding-bottom: 30px;
  
        .Top4IssuesTitle {
          color: white;
          font-weight: bold;
          display: flex;
          align-items: center;
          padding: 10px;
  
          span {
            font-size: 22px;
            margin-right: 5px;
          }
        }
  
        .IssueCardsWrapper {
          display: grid;
          grid-template-columns: 50% 50%;
          justify-items: center;
          row-gap: 20px;
        }
      }
    }

    .AreaSeverityWrapper {
      font-size: 14px;

      .SectionHeader {
        color: #000000;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #FCC306;
        font-weight: bold;
  
        .InfoIconContainer {
          cursor: pointer;
          
          span {
            color: #000000;
            font-size: 16px;
          }
        }
      }

      .SectionContent {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;
        background-color: #666666;
        padding: 20px;    
      }

      .SectionFooter {
        background-color: #444444;
        color: white;
        padding: 20px;

        .GreenCircle {
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 6px;
          margin: 0 5px 0 10px;
          background-color: #8DC63F;
          vertical-align: middle;
        }

        .RedCircle {
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 6px;
          margin: 0 5px 0 10px;
          background-color: #BE1E2D;
          vertical-align: middle;
        }
      }
    }

    .Filler {
      background-color: #FCC306;
      min-height: 50px;
      margin-top: 5px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }

  .TradeMedicFooter {
    display: block;
    position: relative;
    background-color: #333333;
    padding: 20px;
    color: #cccccc;
    font-size: 10px;
    line-height: 20px;
    text-align: left;
  }
}

@media only screen and (max-width: 480px) {
  .TradeMedicContentWrapper {
    background-color: #000000;
  
    .TradeMedicContentHeader {
      height: 50vh;
  
      .LogoBox {
        height: 150px;
        
        .Logo {
          height: 100px;
        }
      }
    }
  
    .TradeMedicMainContent {
      padding: 0 10px;
      margin-bottom: 20px;
  
      .TradeMedicTableWrapper {
    
        .Top4IssuesWrapper {    
          .Top4IssuesTitle {
            font-size: 14px;
          }
    
          .IssueCardsWrapper {
            grid-template-columns: 100%;
          }
        }
      }
  
      .AreaSeverityWrapper {
        font-size: 14px;
  
        .SectionContent {
          padding: 10px;
        }
      }
    }
  
    .TradeMedicFooter {
      padding: 10px;
    }
  }
}