.ChartContainer {
  width: 97.5%;
  background-color: #00333B;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  aspect-ratio: 1 / 1;
  justify-self: end;

  .Chart {
    // use to adjust canvas width and height
    height: 80%;
    width: 100%;
    position: relative;
  }
}

@media only screen and (max-width: 768px) and (min-width: 374px) {
  .ChartContainer {
    width: 100%;
    margin-top: 20px;
  }
}