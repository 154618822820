.RiskReportSectionWrapper {
  margin-top: 5px;

  .SectionHeader {
    cursor: pointer;
    background-color: #06AFC9;
    min-height: 40px;
    padding: 0 10px;
    font-size: 14px;
    color: #FFFFFF;
    font-weight: bold;
    transition: all ease 0.3s;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background-color: #033C45;
    }

    .HeaderLeft {
      display: flex;
      align-items: center;

      span {
        font-size: 20px;
        margin-right: 5px;
      }
    }
  }

  .RiskReportTable {
    width: 100%;
    font-size: 12px;
    translate: all 3s ease-out;

    .Thead {
      background-color: #666666;
      font-weight: bold;
      color: white;

      td {
        padding: 20px 10px;
      }

      .LastCell {
        display: flex;
        justify-content: flex-end;
      }

      td {
        padding: 20px 10px;

        span {
          color: #047F92;
          font-size: 18px;
          cursor: pointer;
        }
      }
    }

    .TableBody {

      .TableRow:nth-child(odd) {
        background-color: #efefef;
      }

      .TableRow:nth-child(even) {
        background-color: #e6e6e6;
      }

      .TableRow {
        .MidCellContentContainer {
          display: flex;
          align-items: center;
          
          .WarningIcon {
            color: #C1272D;
            font-size: 36px;
            margin-right: 10px;
          }
  
          .CheckIcon {
            color: #009245;
            font-size: 36px;
            margin-right: 10px;
          }
        }

        .LastCell {
          display: flex;
          justify-content: flex-end;
          margin-right: 15px;
          margin-top: 10px;
        }

        td {
          padding: 20px 10px;

          span {
            color: #047F92;
            font-size: 18px;
            cursor: pointer;
          }
        }
      }
    }
  }
}