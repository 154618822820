.Form {
  width: 80%;
  margin: auto;

  .Input {
    width: 100%;
  }
}

.CheckboxRow {
  display: grid;
  grid-template-columns: 20px auto;
  gap: 8px;

  input[type="checkbox"] {
    width: 16px;
    height: 16px;
  }

  .CheckboxLabel {
    color: #047F92;
    font-weight: bold;
  }

  .CheckboxDescription {
    font-size: 12px;
    font-weight: 400;
    color: #4D4D4D;
  }
}

.PreviousButton {
  background-color: #047F92;
  color: white;
  font-weight: bold;
  font-size: 18px;
  width: fit-content;
  padding: 8px 12px;
  border: 1px solid #047F92;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  transition: 0.3s all ease;

  &:hover, &:active {
    background-color: #06AFC9;
    transition: 0.3s all ease;
    border: 1px solid #06AFC9;
  }
}

.NextButton {
  background-color: #047F92;
  color: white;
  font-weight: bold;
  font-size: 18px;
  width: fit-content;
  padding: 8px 12px;
  border: 1px solid #047F92;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  float: right;

  &.Disabled {
    background-color: #999999;
    cursor: unset;
    border: 1px solid #999999;
    cursor: pointer;
  }
}

.InfoIcon {
  color: #047F92;
  font-size: 18px;
  margin-left: 3px;
  cursor: pointer;
}