.tradeHistoryContainer {
  width: 100%;
  color: #000000;

  .timeBtnsContainer {
    display: flex;
    margin: 20px 20px 10px 20px;

    .timeButton {
      margin-right: 10px;
      background-color: #b3b3b3;
      color: white;
      font-weight: bold;
      border-radius: 5px;
      width: 30px;
      padding: 3px 1px;
      cursor: pointer;
    }
  }

  .tradeHistoryTable {
    width: calc(100% - 40px);
    margin: 10px 20px;
    text-align: left;

    .titleRow {
      background-color: #06AFC9;
    }

    .actionLabel {
      background-color: #033C45;
      color: white;
      width: 40px;
      text-align: center;
      border-radius: 5px;
      font-weight: bold;
    }
  }

  .bottomContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 20px;

    .searchContainer {
      
    }
  }
}

table { 
  border-collapse:collapse
}

td {
  padding: 10px;
}

th {
  padding: 10px;
}

#InfoIcon {
  color: #047f92;
  font-size: 18px;
  margin: 0 20px;
  text-align: left;
  cursor: pointer;
}