.Container {
  text-align: center;
  margin: 50px auto 0 auto;
}

.SendButton, .ConfirmButton {
  background-color: #047F92;
  color: white;
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
  border-radius: 8px;
  margin-top: 15px;
  cursor: pointer;
  transition: 0.3s all ease;
  user-select: none;
  width: 80%;
  margin: 20px auto 0 auto;

  &:hover, &:active {
    background-color: #06AFC9;
    transition: 0.3s all ease;
  }
}

.SubTitle {
  margin-bottom: 20px;
}

.SendAgain {
  margin: 20px auto 0px auto;
  text-align: right;
  width: 80%;
  cursor: pointer;
  font-weight: bold;
  color: #4D4D4D;
}
