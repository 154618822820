.Section {
  width: 80%;
  margin: 20px auto 0 auto;
  text-align: center;
}

.SectionTitle {
  font-size: 18px;
  font-weight: bold;
  color: #4D4D4D;
  text-align: center;
  margin-bottom: 20px;
}

.SectionDescription {
  font-size: 14px;
  color: #4D4D4D;
  margin-bottom: 20px;
}

.Button {
  background-color: #047F92;
  color: white;
  font-size: 18px;
  width: 100%;
  position: relative;
  top: 60px;
  padding: 8px 0;
  border: 1px solid #047F92;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s all ease;

  &:hover, &:active {
    background-color: #06AFC9;
    border: 1px solid #06AFC9;
    transition: 0.3s all ease;
  }
}

.Help {
  position: relative;
  top: 66px;
  color: #047F92;
  cursor: pointer;
}