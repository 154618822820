.MobileSidebar {
  .Backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.75);
    z-index: 999;
  }

  .SidebarContent {
    position: fixed;
    width: 80%;
    z-index: 1000;
    top: 60px;
    font-size: 16px;
    color: #047F92;
    text-align: left;
    height: 80%;
    animation-name: run;
    animation-duration: 1s;

    .SidebarHeader {
      background-color: #033C45;
      height: 40px;
      display: flex;
      align-items: center;
      padding-left: 10px;

      .CloseContainer {

        .Close {
          font-size: 30px;
          font-weight: 600;
          display: inline-block;
          transform: rotate(45deg);
          color: #06AFC9;
        }
      }

      .HeaderTitle {
        margin-left: 20px;
        font-weight: bold;
      }
    }

    .SidebarBody {
      background-color: #06AFC9;
      height: 100%;

      .ItemTitle {
        font-weight: bold;
        padding: 3px 0 3px 17px;

        .Icon {
          margin-right: 5px;
        }
      }

      .ItemsList {
        padding: 10px 25px;
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 5px;
      }
    }
  }
}

.Selected {
  color: white;
}

.SeperatedLine {
  border: 1px dashed #047F92;
  width: 100%;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

@keyframes run {
  0% {
    left: -80%;
  }
  100% {
    left: 0;
  }
}