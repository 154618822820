.InfoRow {
  margin-top: 12px;

  .InfoLabel {
    font-size: 16px;
    margin-bottom: 5px;
    display: block;
    color: #4D4D4D;
  }
}

.InfoInput {
  padding: 6px 12px;
  border: 1px solid white;
  border-radius: 6px;
  box-sizing: border-box;
}