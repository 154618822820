.ErrorPage {
  background-color: #ABBEC2;
  height: 100%;
  width: 100%;
  color: #047F92;

  .ErrorMessageContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;

    h1 {
      font-size: 64px;
    }
  }
}