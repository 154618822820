.ChartContainer {
  width: 100%;
  background-color: #00333B;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.ChartButtonsContainer {
  background-color: #00333B;
  display: grid;
  grid-template-columns: 100px auto;
  text-align: left;
  padding: 10px;
  height: 44px;
  box-sizing: border-box;
  
  #Trades {
    border-radius: 5px 0 0 5px;
  }

  #Lots {
    border-radius: 0 5px 5px 0;
  }
}

.ModeButtonsContainer {
  display: inline-block;
}

.DualModeButton {
  background-color: #999999;
  color: white;
  display: inline-block;
  cursor: pointer;
  padding: 2.5px 5px;
  font-weight: bold;
  font-size: 14px;
  width: auto;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #06AFC9;
  }

  &.Selected {
    background-color: #047F92;
  }
}

.TimeButtonsContainer {
  text-align: right;
  display: inline-block;
  grid-column: 2/3;

  .TimeButton {
    display: inline-block;
    background-color: #999999;
    color: white;
    width: 30px;
    text-align: center;
    border-radius: 5px;
    margin-left: 5px;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
    height: 24px;
    line-height: 24px;

    &.Selected {
      background-color: #047F92;
    }
  }
}

.LineChartContainer {
  height: calc(100% - 94px);
  width: 100%;
}

.IconContainer {
  position: relative;
}

#InfoIcon {
  position: absolute;
  top: 0;
  left: 10px;
  &:hover {
    color: #06AFC9;
  }
}

.WhiteBackgroundCircle {
  background-color: white;
  height: 15px;
  width: 15px;
  position: absolute;
  top: 12.5px;
  left: 12.5px;
  border-radius: 50%;
}

@media only screen and (max-width: 768px) and (min-width: 374px) {
  .ChartContainer {
    width: 100%;
    background-color: #00333B;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    grid-column: 1/3;
  }
}