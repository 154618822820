.Page {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.AddTradingAccountButton {
  background-color: #047F92;
  color: #FFFFFF;
  text-align: center;
  width: fit-content;
  font-size: 18px;
  padding: 4px 24px;
  border-radius: 12px;
  font-weight: bold;
  margin: 20px auto 20px auto;
  cursor: pointer;
  transition: 0.3s all ease;

  &:hover, &:active {
    background-color: #06AFC9;
    transition: 0.3s all ease;
  }
}

.AddTradingAccountIcon {
  color: white;
  margin-right: 10px;
  font-size: 40px;
  vertical-align: middle;
  font-weight: normal;
}