.FullReportSectionWrapper {
  margin-top: 5px;

  .SectionHeader {
    cursor: pointer;
    background-color: #FCC306;
    min-height: 40px;
    padding: 0 10px;
    font-size: 14px;
    color: #000000;
    font-weight: bold;
    transition: all ease 0.3s;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background-color:#CFA20A;
    }

    .HeaderLeft {
      display: flex;
      align-items: center;

      span {
        font-size: 20px;
        margin-right: 5px;
        font-weight: normal;
      }
    }
  }

  .FullReportTable {
    width: 100%;
    font-size: 12px;
    translate: all 3s ease-out;

    .Thead {
      background-color: #666666;
      font-weight: bold;
      color: white;

      td {
        padding: 20px 10px;
      }

      .LastCell {
        display: flex;
        justify-content: flex-end;
      }
    }

    .TableBody {

      .TableRow:nth-child(odd) {
        background-color: #efefef;
      }

      .TableRow:nth-child(even) {
        background-color: #e6e6e6;
      }

      .TableRow {

        .SeverityBar {
          display: inline-block;
          position: relative;
          width: 100%;
          height: 8px;
          border: 2px solid #ffffff;
          border-radius: 6px;
          background: rgb(38,255,0);
          background: linear-gradient(90deg, rgba(38,255,0,1) 0%, rgba(255,220,0,1) 13%, rgba(255,160,0,1) 40%, rgba(255,0,0,1) 70%, rgba(121,24,24,1) 96%);

          .SeverityBarDot {
            position: absolute;
            width: 8px;
            height: 8px;
            top: -4px;
            padding: 0;
            margin: 0;
            border: 4px solid #FFFFFF;
            border-radius: 50%;
            box-shadow: 1px 1px 3px rgb(0 0 0 / 0.5);
            z-index: 801;
          }
        }

        .LastCell {
          display: flex;
          justify-content: flex-end;
        }

        td {
          padding: 20px 10px;

          span {
            color: #000000;
            font-size: 16px;
            cursor: pointer;
          }
        }
      }
    }
  }
}