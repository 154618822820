.Sidebar {
  width: 60px;
  height: 100%;
  position: fixed;
  top: 60px;
  background-color: #033C45;
  z-index: 99;

  .SidebarItemContainer {
    padding: 0;
    margin: 0;
  }

  .SidebarItem {
    list-style: none;
    padding: 0;
    margin: 0;
    height: 60px;
    font-size: 24px;
    color: #047F92;
    cursor: pointer;

    &.Selected {
      background-color: black;
      color: white;
    }

    .IconContainer {
      line-height: 60px;
    }

    &:hover {
      color: #06AFC9;
    }

    .Icon {
      line-height: 60px;
      height: 60px;
    }

    #alertIcon {
      color: #047F92;
      text-decoration: none;

      &:hover {
        color: #06AFC9;
      }

      #Selected {
        background-color: black;
        color: white;  
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .Sidebar {
    display: none;
  }
}