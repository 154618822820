.SandboxContainer {
  background-image: url('../../assets/login-screen-bg-2.png'), url('../../assets/login-screen-bg.png');
  background-size: auto 90%, cover;
  background-repeat: no-repeat;
  background-position: 24% center, center;
  height: 100%;

  .SandboxHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    margin-left: 10px;

    .Logo {
      height: 32px;
      width: auto;
      padding: 12px 0 0 12px;
    }

    .SandboxLabel {
      position: absolute;
      display: inline-block;
      left: 160px;
      top: 10px;
      font-size: 12px;
      line-height: 12px;
      font-weight: bold;
      background-color: #ffb700;
      padding: 2px 4px;
      border-radius: 6px;
      margin: 10px 0;
    }
  }

  .LoginBox {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: calc(30% - 80px);
    height: calc(80% - 80px);
    background-color: #abbec2;
    position: fixed;
    top: 10%;
    right: 5%;
    border-radius: 20px;
    padding: 40px;
    text-align: left;

    .BoxTitle {
      margin: 10px 0;
    }

    .InputLabel {
      width: 100%;
      margin-top: 20px;
    }

    .InputBox {
      width: calc(100% - 3px);
      margin: 4px 0;
      outline: none;
      border: none;
      padding: 9.25px 0 9.25px 3px;
      font-size: 16px;
      border-radius: 3px;
    }

    .SubmitButton {
      background-color: #047F92;
      color: white;
      font-weight: bold;
      text-align: center;
      padding: 10px 0;
      border-radius: 8px;
      cursor: pointer;
      transition: 0.3s all ease;
      user-select: none;
      margin-top: 20px;

      &:hover, &:active {
        background-color: #06AFC9;
        transition: 0.3s all ease;
      }

      .LoginIcon {
        font-size: 16px;
        vertical-align: middle;
        position: relative;
        bottom: 2px;
      }

      &.DisabledButton {
        background-color: #999999;
      }
    }

    .ErrorMessage {
      color: red;
      font-size: 14px;
    }

    .resendBtn {
      font-size: 12px;
      color: #047F92;
      cursor: pointer;
    }
  }

  .ResendKeyBox {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: calc(30% - 80px);
    height: calc(80% - 80px);
    background-color: #abbec2;
    position: fixed;
    top: 10%;
    right: 5%;
    border-radius: 20px;
    padding: 40px;
    text-align: left;

    .BoxTitle {
      margin: 10px 0;
    }

    .InputLabel {
      width: 100%;
      margin-top: 20px;
    }

    .InputBox {
      width: calc(100% - 3px);
      margin: 4px 0;
      outline: none;
      border: none;
      padding: 9.25px 0 9.25px 3px;
      font-size: 16px;
      border-radius: 3px;
    }

    .SubmitButton {
      background-color: #047F92;
      color: white;
      font-weight: bold;
      text-align: center;
      padding: 10px 0;
      border-radius: 8px;
      cursor: pointer;
      transition: 0.3s all ease;
      user-select: none;
      margin-top: 20px;

      &:hover, &:active {
        background-color: #06AFC9;
        transition: 0.3s all ease;
      }

      .LoginIcon {
        font-size: 16px;
        vertical-align: middle;
        position: relative;
        bottom: 2px;
      }

      &.DisabledButton {
        background-color: #999999;
      }
    }

    .ErrorMessage {
      color: red;
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .SandboxContainer {
    background-size: auto 100vw, cover;
    background-position: center 80px, center;

    .LoginBox {
      position: absolute;
      width: calc(70% - 80px);
      height: 40%;
      top: calc(50% - 80px);
      left: 15%;
    }

    .ResendKeyBox {
      position: absolute;
      width: calc(70% - 80px);
      height: 40%;
      top: calc(50% - 80px);
      left: 15%;
    }
  }
}

@media only screen and (max-width: 500px) {
  .SandboxContainer {
    background-size: auto 110vw, cover;
    background-position: center 50px, center;

    .LoginBox {
      position: absolute;
      width: calc(100% - 40px);
      height: auto;
      top: auto;
      left: 0;
      bottom: 0;
      border-radius: 20px 20px 0 0;
      padding: 10px 20px 40px 20px;
    }

    .ResendKeyBox {
      position: absolute;
      width: calc(100% - 40px);
      height: auto;
      top: auto;
      left: 0;
      bottom: 0;
      border-radius: 20px 20px 0 0;
      padding: 10px 20px 40px 20px;
    }
  }
}