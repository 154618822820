.eightPointAssessmentWrapper {
  display: block;
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
  font-family: "Noto Sans";

  .eightPointAssessmentInfoHeader {
    background-color: #C0D2D4;
    color: #033C45;
    font-size: 14px;
    font-weight: bold;
    height: 50px;
    line-height: 50px;
    padding: 0 10px;
    border-radius: 20px 20px 0 0;
    display: flex;
    justify-content: space-between;

    .InfoIcon {
      font-size: 20px;
      cursor: pointer;
      color: #047F92;
    }
  }

  .eightPointAssessmentBody {
    background-color: #00333B;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
  }

  .eightPointAssessmentFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #00272E;
    padding: 20px;
    border-radius: 0 0 20px 20px;

    .eightPointAssessmentFooterLeft {
      font-size: 14px;
      color: white;

      .GreenDot {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        margin: 0 5px 0 10px;
        background-color: #8DC63F;
        vertical-align: middle;
      }

      .RedDot {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        margin: 0 5px 0 10px;
        background-color: #BE1E2D;
        vertical-align: middle;
      }
    }

    a {
      height: 20px;
      width: auto;
      padding: 2px 5px;
      background-color: #D9D0BF;
      text-align: center;
      text-decoration: none;
      font-size: 14px;
      font-weight: normal;
      color: #047F92;
      border-radius: 6px;
      transition: all 0.3s ease;
      display: flex;
      
      span {
        font-size: 20px;
        margin-right: 3px;
      }

      &:hover {
        background-color: #06AFC9;
        color: #FFFFFF;
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .eightPointAssessmentWrapper {
    display: block;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Noto Sans";
  
    .eightPointAssessmentBody {
      background-color: #00333B;
      padding: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: stretch;
    }
  }
}