.KeyStatisticsContainer {
  .Header {
    background-color: #C0D2D4;
    color: #033C45;
    font-size: 14px;
    font-weight: bold;
    height: 50px;
    line-height: 50px;
    padding: 0 10px;
    border-radius: 20px 20px 0 0;
    display: flex;
    justify-content: space-between;
  }

  .Content {
    display: flex;
    justify-content: space-evenly;
    background-color: #B8C8CA;
    border-radius: 0 0 20px 20px;
    padding: 20px 0;

    .Column {
      width: 100%;
      .Subtitle {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        color: #047F92;
        font-weight: 700;
        font-size: 15px;
      }

      .Value {
        font-size: 36px;
        font-weight: bold;
        color: #00333B;
      }
    }

    #ColumnOne {
      border-right: 1px solid #FFFFFF;
    }

    #ColumnTwo {
      border-right: 1px solid #FFFFFF;
    }

    #ColumnThree {
      border-right: 1px solid #FFFFFF;
    }
  }

  .InfoIcon {
    font-size: 20px;
    cursor: pointer;
    color: #047F92;
  }
}