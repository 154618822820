.CardContainer {
  font-size: 14px;
  color: #FFFFFF;

  .CardHeader {
    display: flex;
    padding: 20px 10px;

    .IconContainer {
      display: inline-block;
      width: 32px;
      height: 32px;
      text-align: center;
      font-size: 20px;
      color: #FFFFFF;
      background-color: #C1272D;
      line-height: 32px;
      border-radius: 6px;
      margin-right: 5px;
    }

    .Titles {
      text-align: left;
    }
  }

  .CardBody {
    display: flex;
    gap: 10px;
    padding: 0 10px 10px 10px;

    img {
      width: 80px;
      height: 70px;
    }
    
    .BodyContent {
      text-align: left;

      .VideoBtn {
        display: inline-block;
        height: 20px;
        width: auto;
        padding: 2px 5px;
        background-color: #D9D0BF;
        text-align: center;
        text-decoration: none;
        font-size: 14px;
        font-weight: normal;
        // line-height: 14px;
        color: #047F92;
        border-radius: 6px;
        transition: all 0.3s ease;
        margin-top: 5px;
        cursor: pointer;

        &:hover {
          background-color: #06AFC9;
          color: #FFFFFF;
        }

        span {
          font-size: 20px;
          vertical-align: middle;
          margin-right: 3px;
        }
      }
    }
  }
}

.newAlert {
  animation: wiggle 2s linear;
}

@keyframes wiggle {
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
}

@media only screen and (max-width: 1024px) {
  #card2 {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}
