.ComparisonSection {
  grid-column: 2/3;
  text-align: left;
  font-size: 14px;
  // background-color:#99A9AD;
  height: auto;

  .SavedNumber {
    float: right;
  }

  .InfoBlock {
    height: 50px;
    line-height: 50px;
    background-color: #333333;
    color:#06AFC9;
    padding-left: 10px;
  }
  .Description {
    color: white;
    background-color:#047F92;
    padding-left: 10px;
    padding-top: 15px;
    padding-bottom: 20px;
  }
  .BrokerSection {
    height: 35px;
    line-height: 35px;
    // min-width: 440px;
    color: white;
    padding: 15px 10px 20px 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .BlockLeft {
      grid-column: 1/2;
      display: inline-flex;
      text-align: center;
      height: 35px;
      width: 100%;
      align-items: center;

      .LogoImg {
        padding-right: 5px;
        // width: 30px;
        height: 70%;
      }
    }

    .BlockRight {
      display: flex;
      height: 70px;
      text-align: right;
      padding-left: 60px;
      font-size: 24px;
      font-weight: 700;
      margin-top: -15px;

      .SavePercentage {
        // display: flex;
        // flex-direction: column;
        height: 50px;
        text-align: right;
        padding-top: 10px;
        font-size: 24px;
        font-weight: 700;
        margin-right: 10px;  
        margin-top: 10px;
        width: 80px;

        .SaveTitle {
          grid-row: 1/2;
          font-size: 14px;
          text-align: right;
          justify-items: start;
          padding: 0;
          height: 20px;
          margin-top: -15px;
        }
      }

      .btn {
        color: #047F92;
        background-color: #E6E6E6;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        border: none;
        border-radius: 5px;
        height: 25px;
        align-self: center;
        padding-left: 5px;
        padding-right: 5px;
        width: 100px;
        cursor: pointer;

        &:hover, &:active {
          background-color: #333333;
          color: white;
        }
      }
    }
  }

  #broker-0 .BlockRight {
    background-image: url('../../assets/highlightBroker.svg');
    background-repeat: no-repeat;
    background-position: 0px bottom;
    // background-color: black;
  }

  #broker-0 {
    background-color: #9C323A;
  }
  #broker-1 {
    background-color: #047F92;
  }
  #broker-2 {
    background-color: #026C7C;
  }
}

@media only screen and (max-width: 1024px) {
  .ComparisonSection {
    // margin-left: 5px;

    .InfoBlock {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
  }

  #broker-2 {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}
