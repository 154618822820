.Subpage {
  padding: 10px;
  display: grid;
  grid-template-columns: minmax(420px, 1fr);
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .Subpage {
    padding: 30px;
    display: grid;
    grid-template-columns: repeat(2, minmax(420px, 1fr));
    gap: 30px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .Subpage {
    padding: 30px;
    display: grid;
    grid-template-columns: repeat(2, minmax(420px, 1fr));
    gap: 30px;
  }
}

@media only screen and (min-width: 1440px) {
  .Subpage {
    padding: 30px;
    display: grid;
    grid-template-columns: repeat(3, minmax(420px, 1fr));
    gap: 30px;
  }
}