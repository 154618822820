.NotificationButtonContainer {
  position: relative;

  .BellButton {
    background-color: #FFFFFF;
    height: 32px;
    width: 32px;
    border-radius: 6px;
    padding-top: 6px;
    padding-left: 6px;
    box-sizing: border-box;
    position: relative;
    top: 14px;
    left: 10px;
    cursor: pointer;
    line-height: 1;
    color: #047F92;
    transition: all 0.3s ease;
  
    &:hover {
      background-color: #06AFC9;
      color: white;
    }
  
    .htBell {
      content: "\e91e";
      font-family: 'ht-font' !important;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
  
      font-size: 20px;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      &:before {
        content: "\e91e";
      }
    }
  }

  .NotificationNumber {
    color: white;
    background-color: #C1272D;
    border-radius: 8px;
    padding: 0 8px;
    font-size: 10px;
    height: 16px;
    line-height: 16px;
    text-align: center;;
    width: fit-content;
    position: absolute;
    right: -12px;
    top: -8px;
  }
  
  .dropdownMenu {
    background-color: white;
    border-radius: 5px;
    padding: 10px 20px;
    width: 350px;
    max-height: 500px;
    position: absolute;
    color: #047F92;
    top: 35px;
    right: 0;
    overflow: hidden;
    overflow-y: scroll;
    font-size: 12px;
    list-style-type: none;
    border-radius: 12px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    background-color: #E9F3F5;
    
    &::-webkit-scrollbar {
      display: none;
    }

    .dropdownTitle {
      font-size: 20px;
      padding: 10px 0 10px 0px;
      font-weight: bold;
      color: black;
    }

    .DropdownMenuSubTitle {
      font-size: 16px;
      color: black;
      font-weight: bold;
    }

    .NotificationCategoryButtonContainer {
      margin-bottom: 10px;
    }

    .NotificationCategoryButton {
      border-radius: 12px;
      background-color: #C0D2D4;
      display: inline-block;
      padding: 4px 10px;
      font-size: 14px;
      cursor: pointer;
      margin-right: 6px;
      color: #033C45;
      transition: 0.2s ease-in;

      &:hover, &:active {
        background-color: #033C45;
        color: white;
        transition: 0.2s ease-in;
      }

      &.SelectedNotificationCategory {
        background-color: #033C45;
        color: white;
        transition: 0.2s ease-in;
      }

      &.Disabled {
        background-color: rgb(173, 169, 169);
        color: white;
      }
    }

    .readNotificationItem {
      cursor: pointer;
      padding: 10px 0px 10px 0px;
      font-weight: normal;
      display: grid;
      grid-template-columns: auto 20px;
      border-bottom: 1px solid #ccc;
      grid-gap: 10px;

      .SeparateLine {
        border-width: 0 0 1px 0;
        border-style: dotted;
        border-color: #047F92;
        margin: 0;
        padding: 5px 0 0 0;
      }
    }

    .unreadNotificationItem {
      cursor: pointer;
      // background-color: #D3D3D3;
      padding: 10px 0px 10px 0px;
      font-weight: bold;
      display: grid;
      grid-template-columns: auto 20px;
      grid-gap: 10px;
      border-bottom: 1px solid #ccc;

      .SeparateLine {
        border-width: 0 0 1px 0;
        border-style: dotted;
        border-color: #047F92;
        margin: 0;
        padding: 5px 0 0 0;
      }
    }
  }
}

.addAlertBtn {
  position: absolute;
  right: 0;
  width: 50px;
  height: 60px;
}