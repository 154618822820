.ChartContainer {
  width: 97.5%;
  background-color: #00333B;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  aspect-ratio: 1 / 1;

  .Chart {
    // use to adjust canvas width and height
    height: 70%;
    width: 100%;
    position: relative;
  }

  .Scoreboard {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: white;
    padding: 0 10px 10px 10px;
    font-family: "Noto Sans";

    .ScoreboardLeft {
      display: flex;
      text-align: left;
      gap: 20px;

      .Label {
        background-color: #009245;
        border-radius: 5px;
        padding-left: 3px;
      }
    }

    .ScoreboardRight {
      text-align: right;
      
      .ScoreboardBtn {
        margin-top: 10px;
        background-color: #D9D0BF;
        color: #047F92;
        padding: 2px 5px;
        border-radius: 5px;
        display: flex;
        font-size: 14px;
        transition: all 0.3s ease;
        cursor: pointer;

        &:hover {
          color: #FFFFFF;
          background-color: #06AFC9;
        }

        span {
          font-size: 20px;
        }
      }
    }
  }
}