.Wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  position: relative;
  font-family: 'Noto Sans';
  
  .ProfitDonut {
    // height: auto;
    width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px;

    .donutChartWrapper {
      position: relative;

      .htUpChart {
        color:#56A07E;
        position: absolute;
        text-align: center;
        font-size: 48px;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 90px;
        height: 90px;
        line-height: 90px;
      }
    }
  }

  .LossDonut {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px;

    .donutChartWrapper {
      position: relative;

      .htUpChart {
        color:#C15357;
        position: absolute;
        text-align: center;
        font-size: 48px;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 90px;
        height: 90px;
        line-height: 90px;
      }
    }
  }

  .PatternsInfo {
    color: #FFFFFF;
    font-size: 12px;

    .PatternValue {
      font-size: 32px;
      font-weight: bold;
    }
  }

  .Insiginificants {
    color: #999999;
    font-size: 12px;
    position: absolute;
    top: 20px;
    left: 45%;
    right: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .InsiginificantsValue {
      font-size: 24px;
      font-weight: bold;
    }
  }
}