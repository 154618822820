.ChartContainer {
  width: 100%;
  background-color: #00333B;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  aspect-ratio: 1 / 1;

  .Chart {
    // use to adjust canvas width and height
    height: 70%;
    width: 100%;
    position: relative;
  }

  .Scoreboard {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    color: #FFFFFF;
    font-size: 10px;
    font-family: "Noto Sans";

    .Left {
      text-align: left;

      .Equity {
        font-size: 12px;
      }

      .EquityChange {
        border-radius: 5px;
        padding: 1px 2px 1px 5px;
      }
    }

    .Right {
      text-align: right;

      .Duration {
        font-size: 12px;
      }
    }

    .Title {
      color: #06AFC9;
    }
  }
}

@media only screen and (min-width: 768px) {
  .ChartContainer {
    width: 97.5%;
    background-color: #00333B;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    aspect-ratio: 1 / 1;
  
    .Chart {
      // use to adjust canvas width and height
      height: 70%;
      width: 100%;
      position: relative;
    }
  
    .Scoreboard {
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
      color: #FFFFFF;
      font-size: 10px;
      font-family: "Noto Sans";
  
      .Left {
        text-align: left;
  
        .Equity {
          font-size: 12px;
        }
  
        .EquityChange {
          border-radius: 5px;
          padding: 1px 2px 1px 5px;
        }
      }
  
      .Right {
        text-align: right;
  
        .Duration {
          font-size: 12px;
        }
      }
  
      .Title {
        color: #06AFC9;
      }
    }
  }
}