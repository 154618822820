.ChartContainer {
  width: 100%;
  background-color: #00333B;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  aspect-ratio: 1 / 1;

  .ChartButtonsContainer {
    background-color: #00333B;
    display: grid;
    grid-template-columns: 100px auto;
    text-align: left;
    padding: 10px;
    height: 44px;
    box-sizing: border-box;
    
    #Pips {
      border-radius: 5px 0 0 5px;
    }
  
    #Percentage {
      border-radius: 0 5px 5px 0;
    }
  }
  
  .ModeButtonsContainer {
    display: inline-block;
  }
  
  .DualModeButton {
    background-color: #999999;
    color: white;
    display: inline-block;
    cursor: pointer;
    padding: 2.5px 5px;
    font-weight: bold;
    font-size: 14px;
    width: auto;
    text-align: center;
    cursor: pointer;
  
    &:hover {
      background-color: #06AFC9;
    }
  
    &.Selected {
      background-color: #047F92;
    }
  }

  .Chart {
    // use to adjust canvas width and height
    height: 75%;
    width: 100%;
    position: relative;
  }
}