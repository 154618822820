.GeneralLayout {
  display: grid;
  grid-template-columns: 60px auto;
  grid-template-rows: 60px auto;
  height: 100%;
  position: relative;
}

.Page {
  grid-column: 2/3;
  grid-row: 2/3;
  height: 100%;
}

.Content {
  height: 100%;
  background-color: #ABBEC2;
}

@media only screen and (max-width: 768px) {
  .GeneralLayout {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 60px auto;
    height: 100%;
  }
  
  .Page {
    grid-column: 1/2;
    grid-row: 2/3;
    height: 100%;
  }
}