.paginationButtonsContainer {
  font-weight: 500;
  font-size: 16px;
  color: #2c3e50;
  display: flex;
}

.button {
  border: none;
  height: 24px;
  width: 24px;
  cursor: pointer;
  font-weight: 700;
}

.previousTenPagesButton {
  background-color: #E6E6E6;
  margin-right: 5px;
  padding: 0 4px;
  border-radius: 5px;
  cursor: pointer;
}

.previousPageButton {
  border: none;
  height: 24px;
  padding: 0 7px;
  cursor: pointer;
  background-color: #E6E6E6;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.nextPageButton {
  border: none;
  height: 24px;
  padding: 0 7px;
  cursor: pointer;
  background-color: #E6E6E6;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.nextTenPagesButton {
  background-color: #E6E6E6;
  margin-left: 5px;
  padding: 0 4px;
  border-radius: 5px;
  cursor: pointer;
}

.activeButton {
  color: white;
  background: #047F92;
}

.inactiveButton {
  color: white;
  background: #B3B3B3;
}