.TradeMedicWrapper {
  padding: 20px;

  .TradeMedicTableWrapper {
    background-color: #c0d2d4;
    border-radius: 20px;
    text-align: left;

    .TableTitle {
      color: #033C45;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .TitleLeft {
        display: flex;
        align-items: center;
        font-weight: bold;

        span {
          font-size: 28px;
          margin-right: 5px;
        }
      }

      .InfoIconContainer {
        margin-right: 10px;
        cursor: pointer;
        
        span {
          color: #047F92;
          font-size: 20px;
        }
      }
    }

    .Top4IssuesWrapper {
      background: linear-gradient(180deg, rgba(13,107,117,1) 39%, rgba(6,175,201,1) 100%);

      .Top4IssuesTitle {
        color: white;
        font-weight: bold;
        display: flex;
        align-items: center;
        padding: 10px;

        span {
          font-size: 22px;
          margin-right: 5px;
        }
      }

      .IssueCardsWrapper {
        display: grid;
        grid-template-columns: 50% 50%;
        justify-items: center;
        padding: 20px;
        row-gap: 20px;
      }
    }
  }

  .Disclaimer {
    font-size: 10px;
    color: #666666;
    text-align: left;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .TradeMedicWrapper {
    .TradeMedicTableWrapper{
      .Top4IssuesWrapper {
        .IssueCardsWrapper {
          display: flex;
          flex-direction: column;
          padding: 5px;
          align-items: center;
        }
      }
    }
  }
}