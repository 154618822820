.phaseSection {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding-top: 15px;
  padding-bottom: 15px;
}

// phase 1
.SignUpBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  .content {
    font-size: 16px;
    color: #4D4D4D;
    width: 80%;
    margin-bottom: 20px;
    padding-left: 25px;
  }

  button {
    width: 240px;
    height: 32px;
    min-width: 240px;
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 700;
    cursor: pointer;
  }

  img {
    width: 25px;
    height: 25px;
  }

  .emailBtn {
    background-color:#047F92;
    color: #FFFFFF;
    margin-bottom: 30px;
    font-size: 16px;
  }

  .googleBtn {
    background-color: #F2F2F2;
    font-size: 16px;
  }

  .fbBtn {
    background-color: #024B9E;
    color: #FFFFFF;
    margin-top: 10px;
    font-size: 16px;
  }
}

// phase 2
.Signupform {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  .formInput {
    border: none;
    border-radius: 5px;
    width: 80%;
    height: 32px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 16px;
    padding: 0;
    box-sizing: border-box;
    padding: 0px 8px;
  }

  .usernameInputSection {
    width: 80%;
    height: 32px;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;

    .usernameInput {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 5px;
      font-size: 16px;
      box-sizing: border-box;
      padding: 0px 8px;
    }

    .checkUsernameLength {
      display: grid;
      justify-items: center;
      width: 32px;
      height: 32px;
      color:#999999;
      font-size: 8px;
      font-weight: 400;
      position: absolute;
      top: 0px;
      right: 0px;
    }
  }

  .validationSection {
    width: 80%;
    height: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #4D4D4D;
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-template-rows: repeat(4, 25px);

    img {
      width: 12px;
      height: 12px;
    }

    .checkStatusTitle {
      grid-column: 1/3;
      grid-row: 1/2;
      font-size: 14px;
    }

    .checkStatus {
      display: flex;
      gap: 3px;
      font-size: 12px;
      align-items: center;
    }

    #checkStatusFive {
      grid-column: 1/3;
      grid-row: 4/5;
    }
  }

  .ButtonContainer {
    width: 80%;
    text-align: right;
  }

  .signUpBtn {
    color:#FFFFFF;  
    border: none;
    border-radius: 5px;
    height: 32px;
    font-size: 14px;
    font-weight: 700;
    padding: 0px 10px;
    vertical-align: baseline;
    margin-top: 5px;
    gap: 8px;

    img {
      position: relative;
      top: 2px;
      margin-left: 10px;
    }
  }

  #enabledBtn {
    background-color: #047F92;
    cursor: pointer;
  }

  #disabledBtn {
    background-color: #999999;
  }


  .AgreementSection {
    width: 80%;
    font-size: 12px;
    color: #4D4D4D;
    margin-top: 5px;;
    margin-bottom: 10px;
    text-align: justify;

    input[type='checkbox'] {
      accent-color:#047F92;
  }

    a:link {
      color:#047F92;
      text-decoration: none;
    }

    a:visited {
      color: #047F92;
      text-decoration: none;
    }
  }

  .DirectToSignIn {
    font-size: 12px;
    color: #4D4D4D;
    align-self: flex-end;
    margin-right: 15%;
    margin-top: 3px;

    .SignInButton {
      color:#047F92;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
.CaptchaSection {
  align-self: center;
  position: relative;
  width: fit-content;
  margin: auto;
}

.ConfirmedEmailSection {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .ConfirmedEmailTitle {
    width: 70%;
    padding-left: 15%;
    font-size: 14px;
    color:#4D4D4D;
    margin-bottom: 25px;
  }
  
  .PinInputContainer {
    width: 100%;
  }

  .confirmSection {
    width: 100%;
    align-self: flex-end;
    margin-top: 15px;

    .ConfirmEmailButtonContainer {
      width: 256px;
      margin: auto;
      text-align: right; 
    }

    .ResendEmailSection {
      color: #4D4D4D;
      font-size: 14px;

      .ResendButton {
        cursor: pointer;
      }
    }

    #confirmBtn {
      background-color: #047F92;
      cursor: pointer;
      color:#FFFFFF;  
      border: none;
      border-radius: 5px;
      height: 32px;
      font-size: 16px;
      font-weight: 700;
      align-items: center;
      justify-content: center;
      margin-top: 5px;
      gap: 8px;

      img {
        vertical-align: top;
        position: relative;
        top: 3px;
        margin-left: 15px;
      }
    }

    #disabledConfirmBtn {
      background-color: #047F92;
      color:#FFFFFF;  
      border: none;
      border-radius: 5px;
      height: 32px;
      font-size: 16px;
      font-weight: 700;
      align-items: center;
      justify-content: center;
      margin-top: 5px;
      gap: 8px;
      opacity: 0.5;

      img {
        vertical-align: top;
        position: relative;
        top: 3px;
        margin-left: 15px;
      }
    }
  }
}

