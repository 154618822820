// mobile
.Subpage {
  padding: 10px;
  display: grid;
  grid-template-columns: minmax(420px, 1fr);
}

// tablet
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .Subpage {
    padding: 30px;
    display: grid;
    grid-template-columns: repeat(2, minmax(420px, 1fr));
    gap: 30px;

    .CostSection {
      background-color: #ABBEC2;
      width: 100%;
      height: auto;
      margin-top: 0;
      display: flex;
      gap: 50px;
    }
  }
}

// desktop
@media only screen and (min-width: 1024px) {
  .Subpage {
    padding: 30px;
    display: grid;
    grid-template-columns: repeat(3, minmax(420px, 1fr));
    gap: 30px;

    .CostSection {
      background-color: #99A9AD;
      width: calc(100% + 30px);
      height: calc(100% + 60px);
      margin-top: -30px;
    }
  }
}
