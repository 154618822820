.carousel-container {
  max-width: calc(100vw - 480px);  
  margin: 0 auto;
  margin-top: 30px;
  position: relative;
}

.slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
}

.card {
  background: #047F92;
  border-radius: 8px;
  overflow: hidden;
  font-size: 14px;

  height: 250px;
  color: #fff;
  cursor: pointer;
}

.card-top > img {
  width: 100%;
  height: 180px;
  object-fit: cover;
  background: black;
}

.card-bottom {
  margin: 10px;
  height: 55px;
  text-align: left;
}

.left-btn {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
  opacity: 0.5;
  cursor: pointer;
  width: 34px;
  transition: all ease 0.3s;
}

.left-btn:hover {
  opacity: 1;
}

.right-btn {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
  opacity: 0.5;
  cursor: pointer;
  width: 34px;
  transition: all ease 0.3s;
}

.right-btn:hover {
  opacity: 1;
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .carousel-container {
    max-width: calc(100vw - 80px);  
  }

  .left-btn {
    position: absolute;
    top: 50%;
  }
  
  .right-btn {
    position: absolute;
    top: 50%;
  }
}

@media only screen and (max-width: 768px) and (min-width: 480px) {
  .carousel-container {
    max-width: calc(100vw + 120px);
  }

  .left-btn {
    position: absolute;
    top: 50%;
  }
  
  .right-btn {
    position: absolute;
    top: 50%;
  }
}

@media only screen and (max-width: 480px) {
  .carousel-container {
    max-width: 100vw;
  }

  .left-btn {
    position: absolute;
    top: 50%;
  }
  
  .right-btn {
    position: absolute;
    top: 50%;
  }
}