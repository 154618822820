.SpeedometerWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  text-align: center;
  height: 45px;
  margin: 10px;
  padding: 85px 5px 5px 5px;
  border-radius: 20px;
  background-color: #00272E;
  font-size: 14px;
  color: #06AFC9;
  line-height: 1.15em;
  transition: all ease 0.5s;
  font-weight: bold;

  &:hover {
    color: white;
    background-color: rgb(4, 127, 146);
    transform: scale(1.15);
  }

  .SpeedometerImage {
    position: absolute;
    width: 120px;
    top: 10px;
    left: calc(50% - 60px);
  }

  .PointerArrow {
    position: absolute;
    width: 120px;
    height: 4px;
    border-radius: 4px;
    background: linear-gradient(90deg, rgba(255,255,255,0) 6%, rgba(255,255,255,1) 7%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 51%);
    top: 68px;
    left: calc(50% - 60px);
  }
  #animation1 {
    animation: hand1 ease 2s forwards;
  }
  #animation2 {
    animation: hand2 ease 2s forwards;
  }
  #animation3 {
    animation: hand3 ease 2s forwards;
  }
  #animation4 {
    animation: hand4 ease 2s forwards;
  }
  #animation5 {
    animation: hand5 ease 2s forwards;
  }
  #animation6 {
    animation: hand6 ease 2s forwards;
  }
  #animation7 {
    animation: hand7 ease 2s forwards;
  }
  #animation8 {
    animation: hand8 ease 2s forwards;
  }
  #animation9 {
    animation: hand9 ease 2s forwards;
  }

  .AssessmentName {
    font-weight: bold;
    font-size: 14px;
  }
}

@media only screen and (max-width: 540px) {
  .SpeedometerWrapper {
    width: 19%;
    padding: 65px 5px 5px 5px;
    border-radius: 10px;
    margin: 5px;
    font-weight: normal;

    .SpeedometerImage {
      width: 60px;
      top: 10px;
      left: calc(50% - 30px);
    }

    .PointerArrow {
      position: absolute;
      width: 60px;
      height: 2px;
      border-radius: 4px;
      background: linear-gradient(90deg, rgba(255,255,255,0) 6%, rgba(255,255,255,1) 7%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 51%);
      top: 39px;
      left: calc(50% - 30px);
    }
  }
}

@keyframes hand1 {
	0% {transform:rotate(0deg);}
	6% {transform:rotate(14deg);}
	100% {transform:rotate(14deg);}
}
@keyframes hand2 {
	0% {transform:rotate(0deg);}
	11% {transform:rotate(34deg);}
	100% {transform:rotate(34deg);}
}
@keyframes hand3 {
	0% {transform:rotate(0deg);}
	17% {transform:rotate(54deg);}
	100% {transform:rotate(54deg);}
}
@keyframes hand4 {
	0% {transform:rotate(0deg);}
	34% {transform:rotate(74deg);}
	100% {transform:rotate(62deg);}
}
@keyframes hand5 {
	0% {transform:rotate(0deg);}
	50% {transform:rotate(94deg);}
	100% {transform:rotate(94deg);}
}
@keyframes hand6 {
	0% {transform:rotate(0deg);}
	59% {transform:rotate(114deg);}
	100% {transform:rotate(114deg);}
}
@keyframes hand7 {
	0% {transform:rotate(0deg);}
	70% {transform:rotate(134deg);}
	100% {transform:rotate(134deg);}
}
@keyframes hand8 {
	0% {transform:rotate(0deg);}
	81% {transform:rotate(154deg);}
	100% {transform:rotate(154deg);}
}
@keyframes hand9 {
	0% {transform:rotate(0deg);}
	100% {transform:rotate(174deg);}
}