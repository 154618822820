.alertModal {
  padding: 20px;
  width: 80vw;
  text-align: left;

  .CrossIconButton {
    width: 32px;
    height: 32px;
    background-color: #FFFFFF;
    border-radius: 6px;
    text-align: center;
    display: inline-block;
    position: absolute;
    right: 20px;

    color: #047F92;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: white;
      background-color: #06AFC9;
    }

    .CancelIcon {
      font-size: 28px;

      line-height: 32px;
      cursor: pointer;
    }
  }

  .alertMsg {
    margin-top: 40px;
  }

  .content {
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 20px;
    margin-top: 20px;

    .contentChart {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .linkBtn {
        background-color: #D9D0BF;
        color: #047F92;
        width: fit-content;
        border-radius: 6px;
        padding: 2px 5px;
        margin-top: 10px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          background-color: #06AFC9;
          color: white;
        }

        #link {
          text-decoration: none;

          &:visited {
            color: inherit;
          }

          #linkContent {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .contentVideo {
      .PlayerWrapper {
        position: relative;
        padding-top: 56.25%;
      }
      
      .YoutubePlayer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .VideoButton {
        background-color: #D9D0BF;
        color: #047F92;
        width: fit-content;
        border-radius: 6px;
        padding: 2px 5px;
        margin-top: 10px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        position: absolute;
        right: 20px;
        transition: all 0.3s ease;
      
        &:hover {
          background-color: #06AFC9;
          color: white;
        }
      
        .VideoIcon {
          display: inline-block;
          font-size: 20px;
          line-height: 23px;
        }
      
        .VideoButtonText {
          display: inline-block;
          line-height: 23px;
          vertical-align: top;
          margin-left: 3px;
          text-decoration: none;

          &:visited {
            color: inherit;
          }
        }
      }
    }
  }

  .spinnerContainer {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
}