.Section {
  width: 80%;
  margin: 20px auto 0 auto;
  text-align: center;
}

.SectionTitle {
  font-size: 18px;
  font-weight: bold;
  color: #4D4D4D;
  text-align: center;
  margin-bottom: 20px;
}

.SectionDescription {
  font-size: 14px;
  color: #4D4D4D;
  margin-bottom: 20px;
  text-align: left;
}