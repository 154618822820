.HoverMenu {
  width: 220px;
  position: fixed;
  left: 60px;
  top: 60px;
  height: 100%;
  background-color: #06AFC9;
  transition: all 0.3s ease;
  text-align: left;
  
  .MenuTitle {
    color: white;
    font-size: 14px;
    font-weight: 900;
    padding: 15px 20px;
    background-color: #047F92;
  }

  .ItemContainer {
    line-height: 32px;

    &.Selected {
      background-color: black;
      
      a {
        color: white;
        &:hover {
          background-color: black;
        }
      }
    }
    
    a {
      color: #047F92;
      text-decoration: none;
      padding: 10px 20px;
      display: block;
      &:hover {
        background-color: #047F92;
        color: white !important;
      }
    }
  }

  .Label {
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
  }

  .ItemIcon {
    font-size: 32px;
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
    height: 32px;
    margin-right: 10px;
  }
}