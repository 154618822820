.overviewWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  position: relative;

  .leftColumn {
    width: calc(100% - 400px);
    height: 100%;

    .tradesPatternContainer {
      display: grid;
      width: calc(100% - 60px);
      margin: 20px auto;

      .tradesPatternHeader {
        background-color: black;
        padding-left: 10px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .tradesPatternTitle {
          font-size: 14px;
          color: white;
          font-weight: bold;
        }

        .iconContainer {
          position: relative;

          .whiteBackgroundCircle {
            background-color: white;
            height: 14px;
            width: 14px;
            position: absolute;
            right: 17px;
            bottom: -6px;
            border-radius: 50%;
          }

          .InfoIcon {
            font-size: 20px;
            position: absolute;
            right: 15px;
            bottom: -8px;
            cursor: pointer;
            color: #047F92;
            &:hover {
              color: #06AFC9;
            }         
          }
        }
      }

      .tradesPatternBody {
        display: grid;
        grid-template-columns: 50% 50%;

        .tradesPatternBodyRight {
          background-color: #00272E;
          border-bottom-right-radius: 20px;
          padding: 10px;

          .progressBarsWrapper {
            display: grid;
            grid-template-columns: 50% 50%;
            margin-top: 10px;
          }
        }
      }
    }

    .chartsWrapper {
      width: calc(100% - 60px);
      display: grid;
      grid-template-columns: 50% 50%;
      margin: 20px auto;
    }
  }

  .rightColumn {
    background-color: #99A9AD;
    width: 400px;
    height: calc(100% + 50px);
    position: absolute;
    right: 0;
    top: -50px;
  }
}

@media only screen and (max-width: 1024px) {
  .overviewWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: static;
    justify-content: flex-start;

    .leftColumn {
      width: 100%;
      height: auto;
  
      .tradesPatternContainer {

        .tradesPatternHeader {
          background-color: black;
          padding: 5px 10px;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
          height: 50px;
  
          .tradesPatternTitle {
            font-size: 14px;
            color: white;
            font-weight: bold;
          }
        }
  
        .tradesPatternBody {
          display: grid;
          grid-template-columns: 50% 50%;
        }
      }
  
      .chartsWrapper {
        width: calc(100% - 60px);
        display: grid;
        grid-template-columns: 50% 50%;
      }
    }
  
    .rightColumn {
      background-color: #ABBEC2;
      width: calc(100% - 60px);
      height: auto;
      position: static;
      display: grid;
      gap: 10px;
      grid-template-columns: 50% 50%;
      margin: 10px auto;
    }
  }
}

@media only screen and (max-width: 768px) and (min-width: 374px) {
  .overviewWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: static;
    justify-content: flex-start;

    .leftColumn {
      width: 100%;
      height: auto;
  
      .tradesPatternContainer {

        .tradesPatternHeader {
          background-color: black;
          padding: 5px 10px;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
          height: 50px;
  
          .tradesPatternTitle {
            font-size: 14px;
            color: white;
            font-weight: bold;
          }
        }
  
        .tradesPatternBody {
          display: grid;
          grid-template-rows: 1fr 1fr;

          .tradesPatternBodyRight {
            grid-row: 2/3;
            grid-column: 1/3;
            border-bottom-left-radius: 20px;
          }
        }
      }
  
      .chartsWrapper {
        width: calc(100% - 60px);
        display: grid;
        grid-template-columns: 100%;
        justify-content: center;
      }
    }
  
    .rightColumn {
      background-color: #ABBEC2;
      width: calc(100% - 60px);
      height: auto;
      position: static;
      display: grid;
      gap: 10px;
      grid-template-columns: 100%;
      margin: 10px auto;
    }
  }
}