.Chart {
  width: 100%;
  padding-bottom: 20px;
  background-color: #00333B;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  // aspect-ratio: 1 / 1;
}

.hoverTextArray {
  height: 15px;
}