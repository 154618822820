.InputWrapper {
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
  margin-top: 15px;
  display: grid;
  grid-template-columns: auto 20px;

  .InputLabel {
    font-size: 10px;
    color: #999999;
    padding-top: 2px;
  }

  .InputTemplate {
    outline: none;
    border: none;
    padding: 8.25px 0;
    font-size: 18px;
    width: 100%;
  }

  .ActualInput {
    font-size: 16px;
    outline: none;
    border: none;
    padding-bottom: 4px;
    display: block;
    margin-top: -2px;
    width: 100%;
  }

  .Icon {
    font-size: 24px;
    padding-top: 6px;
    cursor: pointer;
    color: #999999;
  }

  .ActiveIcon {
    font-size: 24px;
    padding-top: 6px;
    cursor: pointer;
    color: #047F92;
  }
}