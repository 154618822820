.inputInfoContainer {
  height: 600px;
  width: 340px;
  border-radius: 20px;
  background-color: #E9F3F5;

  .infoModalHeader {
    .backBtn {
      margin: 15px 10px;
      display: flex;
      color: #033C45;
      font-weight: bold;
      align-items: center;
      cursor: pointer;

      img {
        background-color: white;
        width: 12px;
        padding: 7px 10px;
        border-radius: 7px;
        margin-right: 10px;
      }
    }
  }

  .infoModalBody {
    text-align: left;
    margin: 0 auto;
    width: 70%;
    color: #4D4D4D;

    .title {
      font-weight: bold;
    }

    .content {
      margin-top: 7px;
      font-size: 14px;
    }
  }
}