// @font-face {
//   font-family: 'ht-font';
//   src:  url('fonts/ht-font.eot?54wuja');
//   src:  url('fonts/ht-font.eot?54wuja#iefix') format('embedded-opentype'),
//     url('fonts/ht-font.ttf?54wuja') format('truetype'),
//     url('fonts/ht-font.woff?54wuja') format('woff'),
//     url('fonts/ht-font.svg?54wuja#ht-font') format('svg');
//   font-weight: normal;
//   font-style: normal;
//   font-display: block;
// }
@font-face {
  font-family: 'ht-font';
  src:  url('fonts/ht-font.eot?6alv1h');
  src:  url('fonts/ht-font.eot?6alv1h#iefix') format('embedded-opentype'),
    url('fonts/ht-font.ttf?6alv1h') format('truetype'),
    url('fonts/ht-font.woff?6alv1h') format('woff'),
    url('fonts/ht-font.svg?6alv1h#ht-font') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap');

.App {
  text-align: center;
  font-family: 'Noto Sans';
  height: 100%;
  background-color: #ABBEC2;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a:visited {
  text-decoration: none;
}

.MuiTooltip-popper[data-popper-placement*="top"]  {
  bottom: -8px !important;
}
