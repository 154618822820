.BarsContainer {
  // display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  display: flex;
  gap: 2%;
  list-style-type: none;
  padding: 0;
  align-items: flex-end;
  margin: 0;
  padding-top: 15px;
  
  li {
    list-style-type: none;
    font-size: 12px;
  }
}

.TopFiveBar {
  border-radius: 10px 10px 0 0;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  max-width: 100px;

  &.profit {
    background-color: #56A07E;

    &:hover {
      background-color: #009245;
    }
  }

  &.loss {
    background-color: #C15357;

    &:hover {
      background-color: #93272D;
    }
  }

  .BarText {
    text-align: left;
  }

  .HoveredBarText {
    position: absolute;
    // width: 150px;
    width: max-content;
    padding: 10px;
    box-shadow: 3px 3px 3px rgb(0 0 0 / 50%);
    transition: display 0.3s ease;
    visibility: hidden;
    opacity: 0;
    height: 0;


    &.IsHovered {
      visibility: visible;
      opacity: 1;
      transition: all 0.3s ease;
      height: auto;
    }
  }
}

.TopFiveChartHeader {
  background-color: #C0D2D4;
  border-radius: 20px 20px 0 0 ;
  padding: 0 10px;
  height: 50px;
  display: grid;
  grid-template-columns: auto 50px;
}

.TopFiveChartTitle {
  text-align: left;
  font-family: 'Noto Sans';
  font-size: 14px;
  font-weight: 700;
  color: #033C45;
  line-height: 50px;
}

.IconContainer {
  line-height: 50px;
  position: relative;

  .Icon {
    font-size: 20px;
    color: #047F92;
    position: absolute;
    left: 15px;
    cursor: pointer;
  }
}

.TopFiveChartBody {
  height: 100px;
  background-color: #B8C8CA;
  padding: 10px 30px 90px;
  border-radius: 0 0 20px 20px;
}

.hoverTextArray {
  display: grid;
  grid-template-columns: 15px auto;
}

.HoveredBarTextLIne {
  line-height: 1em;
}

.WhiteBackgroundCircle {
  background-color: white;
  height: 15px;
  width: 15px;
  position: absolute;
  top: 18px;
  left: 18px;
  border-radius: 50%;
  z-index: 0;
}

.MobileBarsContainer {
  display: none;
}

@media only screen and (max-width: 768px) {
  .BarsContainer {
    display: none;
  }

  .TopFiveChartBody {
    border-radius: 0 0 20px 20px;
    height: auto;
    padding: 10px;
  }

  .MobileBarsContainer {
    display: flex;
    flex-direction: column;
  }
}

.NoDataAvailable {
  font-size: 20px;
  font-weight: bold;
  padding-top: 40px;
}