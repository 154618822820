.Container {
  text-align: center;
}

.LoginButton {
  background-color: #047F92;
  color: white;
  width: fit-content;
  font-size: 18px;
  font-weight: bold;
  padding: 8px 30px;
  border-radius: 8px;
  border: 1px solid #047F92;
  margin: 20px auto;
  cursor: pointer;
  transition: 0.3s all ease;

  &:hover, &:active {
    background-color: #06AFC9;
    border: 1px solid #06AFC9;
    transition: 0.3s all ease;
  }
}