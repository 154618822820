.SelectionContainer {
  width: 80%;
  margin:  20px auto 40px auto;
  height: 100%;
}

.SectionTitle {
  font-size: 18px;
  font-weight: bold;
  color: #4D4D4D;
}

.SectionDescription {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 20px;
}

.SelectionBtnContainer {
  width: 100%;

  .AccountTypeBtn {
    background-color: #047F92;
    color: white;
    // padding: 5px;
    font-weight: bold;
    margin-bottom: 10px;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;

    img {
      width: 30px;
      height: 30px;
      margin-right: 20px;
    }
  }
}