.BannerContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;

  img {
    max-width: 1024px;
  }
}

.MobileBannerContainer {
  display: none;
}

@media only screen and (max-width: 480px) {
  .BannerContainer {
    display: none;
  }

  .MobileBannerContainer {
    width: 100%;
    display: block;
    margin: 10px auto;

    img {
      width: 100%;
      max-width: 375px;
    }
  }
}