.AlertInfoModal {
  padding: 20px;

  .CrossIconButton {
    width: 32px;
    height: 32px;
    background-color: #FFFFFF;
    border-radius: 6px;
    text-align: center;
    display: inline-block;
    position: absolute;
    right: 20px;

    color: #047F92;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: white;
      background-color: #06AFC9;
    }

    .CancelIcon {
      font-size: 28px;

      line-height: 32px;
      cursor: pointer;
    }
  }
}

.ContentContainer {
  text-align: left;
  margin-top: 20px;
}