.ChartContainer {
  width: 100%;
  background-color: #00333B;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  aspect-ratio: 1 / 1;

  .Chart {
    // use to adjust canvas width and height
    height: 40%;
    width: 100%;
    position: relative;
  }

  .SymbolsContainer {
    display: grid;
    grid-template-columns: repeat(5, 20%);
    align-items: flex-end;
    justify-items: center;
    margin-top: 10px;

    color: #06AFC9;
    font-size: 10px;
    height: 30%;
    width: 100%;

    .SymbolContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 0px;

      .SymbolColor {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-bottom: 5px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .ChartContainer {
    width: 97.5%;
    background-color: #00333B;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    aspect-ratio: 1 / 1;
  
    .Chart {
      // use to adjust canvas width and height
      height: 40%;
      width: 100%;
      position: relative;
    }
  
    .SymbolsContainer {
      display: grid;
      grid-template-columns: repeat(5, 20%);
      align-items: flex-end;
      justify-items: center;
      margin-top: 10px;
  
      color: #06AFC9;
      font-size: 10px;
      height: 30%;
      width: 100%;
  
      .SymbolContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 0px;
  
        .SymbolColor {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-bottom: 5px;
        }
      }
    }
  }
}