.alertContainer {
  width: 100%;
  color: #000000;

  .alertPageHeader {
    margin: 20px 20px 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .headerRight {
      background-color: #b8c8ca;
      border-radius: 16px;
      padding: 0 16px;
      height: 32px;
      display: inline-block;
      line-height: 32px;
      font-size: 12px;
      margin-bottom: -10px;
    }
  }

  .alertTable {
    width: calc(100% - 40px);
    margin: 20px;
    text-align: left;
    font-size: 12px;

    .titleRow {
      background-color: #06AFC9;
    }
  }

  .bottomContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 20px;
  }
}

table { 
  border-collapse:collapse;
}

td {
  padding: 10px;
}

th {
  padding: 10px;
}

#videosBtn {
  font-weight: 400;
  color: #047F92;
  background-color: #D9D0BF;
  width: 80%;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  padding: 3px 0px;
  text-decoration: none;
  cursor: pointer;
}

.itemWithIcon {
  display: flex;
  align-items: center;
}