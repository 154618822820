.orderRow {
  color: #047F92;
  cursor: pointer;

  &:hover {
    color: white;
  }
  
  .dropdownMenu {
    background-color: white;
    border-radius: 5px;
    padding: 3px;
    width: 80px;
    position: absolute;
    right: 20px;
    color: #047F92;

    .iconContainer {
      display: flex;
      align-items: center;
      font-weight: bold;
      padding: 5px 0;
      font-size: 14px;

      span {
        font-size: 18px;
      }
    }

    &:hover {
      color: #047F92;
    }
  }

  .actionBtnContainer {
    height: 100%;
    
    .actionBtn {
      display: flex;
      justify-content: flex-end;
      margin-right: 20px;
      font-size: 16px;
      text-align: center;

      .CircleDotsContainer {
        width: fit-content;
        
        .CircleDot {
          border-radius: 50%;
          width: 6px;
          height: 6px;
          background-color: #fff;
          display: block;
          margin: 1px;
          display: inline-block;
        }
      }


    }

    &:hover {
      
    }
  }
}

.orderRow:nth-child(odd) {
  background-color: #9AB8BD;

  &:hover {
    background-color: #047F92;
  }
}

.orderRow:nth-child(even) {
  background-color: #B3C4C8;

  &:hover {
    background-color: #047F92;
  }
}