.Breadcrumb {
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  color: #047F92;
  text-align: left;
  background-color: #B8C8CA;
  padding-left: 30px;

  .CurrentPage {
    color: black;
    font-weight: bold;
  }
}

.HamburgerMenu {
  display: none;
}

@media only screen and (max-width: 768px) {
  .Breadcrumb {
    height: 40px;
    font-size: 12px;
    color: #06AFC9;
    text-align: left;
    background-color: #033C45;
    padding-left: 10px;
    display: flex;
    align-items: center;
    gap: 10px;

    .CurrentPage {
      color: white;
      font-weight: bold;
    }
  }

  .HamburgerMenu {
    display: inline-block;
    cursor: pointer;
    line-height: 50px;
  
    .BarOne {
      width: 20px;
      height: 3px;
      background-color: #333;
      transition: 0.4s;
      background-color: #047F92;
      margin: 3px 0;
    }
  
    .BarTwo {
      width: 20px;
      height: 3px;
      background-color: #333;
      transition: 0.4s;
      background-color: #047F92;
      margin: 3px 0;
    }
  
    .BarThree {
      width: 17px;
      height: 3px;
      background-color: #333;
      transition: 0.4s;
      background-color: #047F92;
      margin: 3px 0;
    }
  }
}