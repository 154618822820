.AlertContainer {
  width: 100%;
  
  .AlertHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 10px;
    background-color: #333333;
    color: #06AFC9;
    font-size: 14px;
    font-weight: bold;

    .AlertHeaderRight {
      display: flex;
      gap: 10px;
      align-items: center;

      .InfoIcon {
        font-size: 20px;
        cursor: pointer;
        color: #047F92;
        transition: all 0.3s ease;
        
        &:hover {
          color: #06AFC9;
        }
      }
  
      .ViewAllBtn {
        display: inline-block;
        height: 20px;
        width: auto;
        padding: 2px 5px;
        background-color: #D9D0BF;
        text-align: center;
        text-decoration: none;
        font-size: 14px;
        font-weight: normal;
        color: #047F92;
        border-radius: 6px;
        transition: all 0.3s ease;

        &:hover {
          background-color: #06AFC9;
          color: #FFFFFF;
        }

        span {
          font-size: 20px;
          vertical-align: middle;

          &:hover {
            color: white;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .AlertContainer {

    .AlertHeader {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
  }
}
