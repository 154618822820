.Chart {
  width: 100%;
  padding-bottom: 20px;
  background-color: #00333B;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  aspect-ratio: 1 / 1;
}

.ChartHeader {
  background-color: black;
  padding: 5px 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: grid;
  grid-template-columns: auto 148px;
  height: 50px;
  box-sizing: border-box; 
  width: 100%;
 

  .ChartTitleContainer {
    position: relative;
    width: 100%;  
  }

  .ChartTitle {
    font-size: 14px;
    color: white;
    // width: 300px;
    font-weight: bold;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    // width: 100%;
  }

  .MinorIconsContainer {
    display: inline-block;
    display: grid;
    grid-template-columns: repeat(4, 37px);
  }

  .TitleIcon {
    display: inline-block;
    color: #D9D0BF;
    font-size: 20px;
    vertical-align: middle;
    line-height: 40px;
    cursor: pointer;

    &.InfoIcon {
      color: #047F92;
    }

    &.Selected {
      color: #FEC009;
    }
  }
}

.ChartButtonsContainer {
  background-color: #00333B;
  display: grid;
  grid-template-columns: 100px auto;
  text-align: left;
  padding: 10px;
  height: 44px;
  box-sizing: border-box;
  
  #Pips {
    border-radius: 5px 0 0 5px;
  }

  #Percentage {
    border-radius: 0 5px 5px 0;
  }
}

.ModeButtonsContainer {
  display: inline-block;
}

.DualModeButton {
  background-color: #999999;
  color: white;
  display: inline-block;
  cursor: pointer;
  padding: 2.5px 5px;
  font-weight: bold;
  font-size: 14px;
  width: 29px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #06AFC9;
  }

  &.Selected {
    background-color: #047F92;
  }
}

.TimeButtonsContainer {
  text-align: right;
  display: inline-block;
  grid-column: 2/3;

  .TimeButton {
    display: inline-block;
    background-color: #999999;
    color: white;
    width: 30px;
    text-align: center;
    border-radius: 5px;
    margin-left: 5px;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
    height: 24px;
    line-height: 24px;

    &.Selected {
      background-color: #047F92;
    }
  }
}

.ChartContainer {
  height: calc(100% - 94px);
  width: 100%;
}

.IconContainer {
  position: relative;
}

#InfoIcon {
  position: absolute;
  top: 0;
  left: 10px;
  &:hover {
    color: #06AFC9;
  }
}

.WhiteBackgroundCircle {
  background-color: white;
  height: 15px;
  width: 15px;
  position: absolute;
  top: 12.5px;
  left: 12.5px;
  border-radius: 50%;
}

