.ModalWrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90vh;
  z-index: 99999;
  background-color: #E9F3F5;
  border-radius: 20px;
  height: auto;
}

.Backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.75);
  z-index: 9999;
}

*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

