.Page {
  display: grid;
  grid-template-columns: 60% 40%;
  height: 100%;

  .DecorationImage {
    background-image: url('../assets/brain-logo-only.png');
    background-size: cover;
    background-position: 47% 50%;
  }

  .RightColumn {
    position: relative;

    .FormContainer {
      position: absolute;
      width: 80%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      // max-width: 400px;
    }
  }
}

@media only screen and (max-width: 375px) {
  .Page {
    display: block;

    .RightColumn {
      position: static;
    }
  }

  .DecorationImage {
    display: none;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 376px) {
  .Page {
    display: block;

    .DecorationImage {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    .RightColumn {
      position: relative;
      top: 50%;
      left: 50%;
      width: 80%;
      transform: translate(-50%, -50%);
      background-color: #ABBEC2;
      border-radius: 10px;
      padding: 10px 0 20px 0;
      min-height: fit-content;

      .FormContainer {
        position: static;
        width: 100%;
        // max-width: 400px;
        top: 0;
        left: 0;
        transform: translate(0, 0);
        margin: auto;
      }
    }
  }
}