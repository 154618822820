.validationSection {
  height: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #4D4D4D;
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: repeat(4, 25px);

  img {
    width: 12px;
    height: 12px;
  }

  .checkStatusTitle {
    grid-column: 1/3;
    grid-row: 1/2;
    font-size: 14px;
  }

  .checkStatus {
    display: flex;
    gap: 3px;
    font-size: 12px;
    align-items: center;
  }

  #checkStatusFive {
    grid-column: 1/3;
    grid-row: 4/5;
  }
}

.Input {
  font-size: 16px;
  padding: 6px;
}

.SpinnerContainer {
  margin-top: 50px;
  text-align: center;
}

.ForgetPasswordSection {
  width: 80%;
  margin: auto;
  color: #4D4D4D;
  margin-top: 30px;
}

.InputWrapper {
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
  margin-top: 15px;

  .InputLabel {
    font-size: 10px;
    color: #999999;
    padding-top: 2px;
  }

  .InputTemplate {
    outline: none;
    border: none;
    padding: 8.25px 0;
    font-size: 18px;
    width: 100%;
  }

  .ActualInput {
    font-size: 16px;
    outline: none;
    border: none;
    padding-bottom: 4px;
    display: block;
    margin-top: -2px;
    width: 100%;
  }
}

.SubmitButton {
  background-color: #999999;
  color: white;
  font-weight: bold;
  border-radius: 8px;
  text-align: center;
  padding: 10px 0;
  margin-top: 40px;
  
  &.active {
    background-color: #047F92;
    cursor: pointer;
    &:hover {
      background-color: #06afc9;
    }
  }
}

.ResetPasswordButton {

  cursor: pointer;
  color: white;
  background-color: #999999;
  border-radius: 8px;
  text-align: center;
  padding: 10px 0;
  margin-top: 40px;
  
  &.active {
    background-color: #047F92;
    &:hover, &:active {
      background-color: #06afc9;
    }
  }
}

.SignInButton, .ResendEmailButton {
  cursor: pointer;
  color: white;
  background-color: #047F92;
  border-radius: 8px;
  text-align: center;
  padding: 10px 0;
  // position: relative;
  // top: 40px;
  margin-top: 40px;
  font-weight: bold;
  transition: 0.3s all ease;

  &:hover, &:active {
    background-color: #06afc9;
    transition: 0.3s all ease;
  }
}

.ResetSuccessSection {
  width: 80%;
  margin: 40px auto;
  text-align: center;
  color: #4D4D4D;

  .CheckMarkContainer {
    margin-top: 40px;
  }
  img {
    width: 50px;
  }
}

.GoToSignIn {
  margin-top: 60px;
  cursor: pointer;
}

@media only screen and (max-width: 1024px)  {
  .GoToSignIn {
    margin-top: 30px;
    cursor: pointer;
  }
}