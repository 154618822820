.headerLeft {
  .buttonsContainer {
    display: flex;
    margin-top: 5px;
    position: relative;

    .filterTitle {
      margin-right: 5px;
      color: #047F92;
      font-weight: 500;
    }

    #btnAll {
      font-size: 16px;
      font-weight: bold;
    }

    .filterBtn {
      width: 20px;
      height: 20px;
      background-color: #D9D0BF;
      font-size: 22px;
      border-radius: 6px;
      padding: 2px;
      color: #047F92;
      margin-right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all ease 0.3s;

      &:hover {
        background-color: #06AFC9;
        color: white;
      }
    }

    .hoveredText {
      font-size: 16px;
      position: absolute;
      bottom: 20px;
    }
  }
}