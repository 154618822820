.chartInfoModal {
  text-align: left;
  width: 80vw;
  max-width: 768px;
  height: auto;
}

.ChartInfoModalHeader {
  padding: 20px 20px 0 20px;
  top: 0;
  width: 100%;
  box-sizing: border-box;
}

.IconButton {
  display: inline-block;
  width: 60px;
  height: 60px;
  background-color: #FFFFFF;
  line-height: 60px;;
  border-radius: 12px;
  text-align: center;
  margin-right: 10px;
}

.CrossIconButton {
  width: 32px;
  height: 32px;
  background-color: #FFFFFF;
  border-radius: 6px;
  text-align: center;
  display: inline-block;
  position: absolute;
  right: 20px;

  color: #047F92;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: white;
    background-color: #06AFC9;
  }

  .CancelIcon {
    font-size: 28px;

    line-height: 32px;
    cursor: pointer;
  }
}

.Icon {
  font-size: 36px;
  line-height: 60px;

}

.SectionTitle {
  font-weight: bold;
  margin: 16px 0;
}

.D9D0BF {
  color: #D9D0BF;
}

.C1272D {
  color: #C1272D;
}

.LegendsContainer {
  text-align: start;
}

.Legend {
  text-align: center;
  display: inline-block;
  width: fit-content;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 40px;
}

.LegendImage {
  height: 20px;
}

.VideoButton {
  background-color: #D9D0BF;
  color: #047F92;
  width: fit-content;
  border-radius: 6px;
  padding: 2px 5px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: #06AFC9;
    color: white;
  }

  .VideoIcon {
    display: inline-block;
    font-size: 20px;
    line-height: 23px;
  }

  .VideoButtonText {
    display: inline-block;
    line-height: 23px;
    vertical-align: top;
    margin-left: 3px;
  }
}

.ChartInfoModalBody {
  margin-top: 0px;
  overflow-y: auto;
  padding: 0 20px;
  display: block;
  max-height: calc(90vh - 120px);
  margin-bottom: 20px;
}

.PlayerWrapper {
  position: relative;
  padding-top: 56.25%;
}

.YoutubePlayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}