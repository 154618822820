.Subpage {
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;

  .ChartsContainer {
    padding-top: 30px;
    display: grid;
    grid-template-columns: 100%;
    gap: 30px;
  }
}

// tablet
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .Subpage {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
  
    .ChartsContainer {
      padding-top: 30px;
      display: grid;
      grid-template-columns: repeat(2, minmax(420px, 1fr));
      gap: 30px;  
    }
  }
}

// desktop
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .Subpage {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
  
    .ChartsContainer {
      padding-top: 30px;
      display: grid;
      grid-template-columns: repeat(2, minmax(420px, 1fr));
      gap: 30px;  
    }
  }
}

@media only screen and (min-width: 1440px) {
  .Subpage {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
  
    .ChartsContainer {
      padding-top: 30px;
      display: grid;
      grid-template-columns: repeat(3, minmax(420px, 1fr));
      gap: 30px;
    }
  }
}