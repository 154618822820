.searchBarContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 10px;

  input {
    height: 32px;
    border: none;
    border-radius: 5px;
    padding-left: 7px;
  }

  button {
    border: none;
    height: 33px;
    border-radius: 5px;
    position: absolute;
    right: 0;
    cursor: pointer;
  }
}