.generalInfoWrapper {
  display: block;
  // margin: 20px 30px;
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
  font-family: "Noto Sans";

  .generalInfoheader {
    background-color: #C0D2D4;
    color: #033C45;
    font-size: 14px;
    font-weight: bold;
    height: 50px;
    line-height: 50px;
    padding: 0 10px;
    border-radius: 20px 20px 0 0;
    display: flex;
    justify-content: space-between;

    .InfoIcon {
      font-size: 20px;
      cursor: pointer;
      color: #047F92;
    }
  }

  .generalInfoBody {
    background-color: #B8C8CA;
    font-size: 14px;
    border-radius: 0 0 20px 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    color: #047F92;

    .bodyColumns {
      padding: 10px 15px;
      margin: 10px 0;

      display: flex;
      flex-direction: column;
      // justify-content: flex-end;
    }

    #centerColumn {
      border-right: 1px solid #FFFFFF;
      border-left: 1px solid #FFFFFF;
    }

    .rows {
      display: flex;
      justify-content: space-between;
    }
  }
}

@media only screen and (max-width: 768px) {
  .generalInfoWrapper {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;

    .generalInfoBody {
      grid-template-columns: 1fr;

      .bodyColumns {
        padding: 10px 15px;
        margin: 0;
  
        display: flex;
        flex-direction: column;
        // justify-content: flex-end;
      }

      #centerColumn {
        border-right: 0;
        border-left: 0;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
      }

      .rows {
        align-items: center;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .generalInfoWrapper {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;

    .generalInfoBody {
      grid-template-columns: 1fr;

      .bodyColumns {
        padding: 10px 15px;
        margin: 0;
  
        display: flex;
        flex-direction: column;
        // justify-content: flex-end;
      }

      #centerColumn {
        border-right: 0;
        border-left: 0;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
      }

      .rows {
        align-items: center;
      }
    }
  }
}