.TradeMedicInfoModal {
  width: 80vw;
  background-color: #efefef;
  border-radius: 20px;
  padding-bottom: 20px;

  .ModalHeader {
    text-align: right;
    padding: 20px;

    .CrossIconButton {
      width: 32px;
      height: 32px;
      background-color: #FFFFFF;
      border-radius: 6px;
      text-align: center;
      display: inline-block;
      color: #000000;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: white;
        background-color: #FFFFFF;
        background-color: #000000;
      }
  
      .CancelIcon {
        font-size: 28px;
        line-height: 32px;
        cursor: pointer;
      }
    }

    .MobileCloseLine {
      display: none;
    }
  }

  .ModalContent {
    display: block;
    max-height: calc(100vh - 160px);
    overflow-y: auto;
    padding: 0 20px;

    .InfoDetailContainer {
      display: flex;
      position: relative;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: stretch;
      padding: 0 0 20px 0;
      text-align: left;

      h3 {
        font-weight: normal;
        margin: 0 0 5px;
        font-size: 14px;
      }

      h2 {
        margin: 0;
      }

      .InfoDetailBoxOne {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: stretch;
        width: calc(50% - 35px);
        padding: 15px;
        border-style: solid;
        border-color: #cccccc;
        transition: all ease 0.5s;
        border-width: 2px 1px 1px 2px;
        border-radius: 20px 0 0 0;
      }

      .InfoDetailBoxTwo {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: stretch;
        width: calc(50% - 35px);
        padding: 15px;
        border-style: solid;
        border-color: #cccccc;
        transition: all ease 0.5s;
        border-width: 2px 2px 1px 1px;
        border-radius: 0 20px 0 0;

        .TextContainer {
          position: relative;

          span {
            position: absolute;
            left: -20px;
            top: -10px;
            font-size: 60px;
            font-weight: normal;
          }

          h2 {
            padding-left: 40px;
          }
        }
      }

      .InfoDetailBoxThree {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: stretch;
        width: calc(50% - 35px);
        padding: 15px;
        border-style: solid;
        border-color: #cccccc;
        transition: all ease 0.5s;
        border-width: 1px 1px 2px 2px;
        border-radius: 0 0 0 0;

        .PerformanceText {
          color: #C1272D;
          font-size: 24px;
          font-weight: bold;
        }
      }

      .InfoDetailBoxFour {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: stretch;
        width: calc(50% - 35px);
        padding: 15px;
        border-style: solid;
        border-color: #cccccc;
        transition: all ease 0.5s;
        border-width: 1px 2px 2px 1px;
        border-radius: 0 0 0 0;

        .SeverityContainer {
          display: flex;
          flex-direction: row;
          align-items: center;

          .SeverityBar {
            display: inline-block;
            position: relative;
            width: 50%;
            height: 20px;
            border: 1px solid #ffffff;
            border-radius: 14px;
            background: rgb(38,255,0);
            background: linear-gradient(90deg, rgba(38,255,0,1) 0%, rgba(255,220,0,1) 13%, rgba(255,160,0,1) 40%, rgba(255,0,0,1) 70%, rgba(121,24,24,1) 96%);
      
            .ServityBarDot {
              position: absolute;
              width: 16px;
              height: 16px;
              padding: 0;
              margin: 2px;
              background-color: #ffffff;
              border-radius: 50%;
              box-shadow: 1px 1px 3px rgb(0 0 0 / 0.5);
              z-index: 801;
            }
          }
  
          .SeverityText {
            color: #C1272D;
            font-size: 24px;
            font-weight: bold;
            padding-left: 20px;
          }
        }
      }

      .InfoDetailBoxFiveGreen {
        background-color: #009245;
        border-width: 0px 2px 2px 2px;
        border-radius: 0 0 20px 20px;
        color: #ffffff;
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: stretch;
        width: calc(100% - 48px);
        min-height: 40px;
        margin: 0;
        padding: 20px;
        border-style: solid;
        border-color: #cccccc;
        transition: all ease 0.5s;

        .MessageContainer {
          display: flex;
          align-items: center;
          position: relative;

          span {
            font-size: 72px;
          }

          .MessageText {
            padding-left: 10px;
          }
        }
      }

      .InfoDetailBoxFiveRed {
        background-color: #C1272D;
        border-width: 0px 2px 2px 2px;
        border-radius: 0 0 20px 20px;
        color: #ffffff;
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: stretch;
        width: calc(100% - 48px);
        min-height: 40px;
        margin: 0;
        padding: 20px;
        border-style: solid;
        border-color: #cccccc;
        transition: all ease 0.5s;

        .MessageContainer {
          display: flex;
          align-items: center;
          position: relative;

          span {
            font-size: 72px;
          }

          .MessageText {
            padding-left: 10px;
          }
        }
      }
    }

    .ExplanationBox {
      text-align: left;
      border-top: 2px dotted #cccccc;
      border-bottom: 2px dotted #cccccc;
      margin-top: 0px;
      padding-bottom: 20px;
    }

    .ReportChartContainer {
      display: grid;
      grid-template-columns: 45% 55%;

      .ContentLeft {
        margin-right: 20px;
      }

      .ContentRight {
        text-align: left;

        .FurtherDetailBox {
          .Subtitle {
            font-weight: bold;
            margin: 10px 0;
          }

          .FurtherInformation {
            display: grid;
            grid-template-columns: 50% 50%;
            color: blue;
          }
        }
      }

    }
  }
}

.TradeMedicGeneralInfo {
  padding: 20px;
  width: 80vw;
  text-align: left;
  background-color: #efefef;
  border-radius: 20px;

  .ModalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .IssueName {
      font-size: 14px;
      color: #000000;
      font-weight: bold;
    }

    .CrossIconButton {
      width: 32px;
      height: 32px;
      background-color: #FFFFFF;
      border-radius: 6px;
      text-align: center;
      display: inline-block;
  
      color: #000000;
      transition: all 0.3s ease-in-out;
      &:hover {
        color: white;
        background-color: #000000;
      }
  
      .CancelIcon {
        font-size: 28px;
        line-height: 32px;
        cursor: pointer;
      }
    }
  }

  .ModalContent {
    margin-top: 20px;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .TradeMedicInfoModal {
    width: 100vw;
    border-radius: 20px;
    padding-bottom: 0px;
    height: 100vh;
  
    .ModalHeader {
      padding: 20px;
      text-align: center;
  
      .CrossIconButton {
        display: none;
      }

      .MobileCloseLine {
        display: inline-block;
        width: 100px;
        height: 6px;
        background-color: #000000;
        border-radius: 3px;
        margin: 10px auto 20px;
        color: #047F92;
        float: none;
        font-size: 0px;
        line-height: 0px;
        text-decoration: none;
        transition: none;
      }
    }
  
    .ModalContent {
      max-height: calc(100vh - 150px);
      padding: 0 10px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .TradeMedicInfoModal {
    width: 100vw;
    border-radius: 20px;
    padding-bottom: 0px;
    height: 100vh;
  
    .ModalHeader {
      padding: 20px;
      text-align: center;
  
      .CrossIconButton {
        display: none
      }
  
      .MobileCloseLine {
        display: inline-block;
        width: 100px;
        height: 6px;
        background-color: #000000;
        border-radius: 3px;
        margin: 10px auto 20px;
        color: #047F92;
        float: none;
        font-size: 0px;
        line-height: 0px;
        text-decoration: none;
        transition: none;
      }
    }
  
    .ModalContent {
      display: block;
      max-height: calc(100vh - 160px);
      overflow-y: auto;
      padding: 0 20px;
  
      .InfoDetailContainer {
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;
        padding: 0 0 20px 0;
        text-align: left;
  
        h3 {
          font-weight: normal;
          margin: 0 0 5px;
          font-size: 14px;
        }
  
        h2 {
          margin: 0;
          font-size: 18px;
        }
  
        .InfoDetailBoxOne {
          width: 100%;
          border-width: 2px 2px 1px 2px;
          border-radius: 20px 20px 0 0;
        }
  
        .InfoDetailBoxTwo {
          width: 100%;
          border-width: 1px 2px 1px 2px;
          border-radius: 0 0 0 0;
  
          .TextContainer {
            position: relative;
  
            span {
              position: absolute;
              left: -20px;
              top: -10px;
              font-size: 60px;
              font-weight: normal;
            }
  
            h2 {
              padding-left: 40px;
            }
          }
        }
  
        .InfoDetailBoxThree {
          width: 100%;
          border-width: 1px 2px 1px 2px;
          border-radius: 0 0 0 0;
  
          .PerformanceText {
            color: #C1272D;
            font-size: 18px;
            font-weight: bold;
          }
        }
  
        .InfoDetailBoxFour {
          width: 100%;
          border-width: 1px 2px 1px 2px;
          border-radius: 0 0 0 0;
  
          .SeverityContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
  
            .SeverityBar {
              display: inline-block;
              position: relative;
              width: 80%;
              height: 20px;
              border: 1px solid #ffffff;
              border-radius: 14px;
              background: rgb(38,255,0);
              background: linear-gradient(90deg, rgba(38,255,0,1) 0%, rgba(255,220,0,1) 13%, rgba(255,160,0,1) 40%, rgba(255,0,0,1) 70%, rgba(121,24,24,1) 96%);
        
              .ServityBarDot {
                position: absolute;
                width: 16px;
                height: 16px;
                padding: 0;
                margin: 2px;
                background-color: #ffffff;
                border-radius: 50%;
                box-shadow: 1px 1px 3px rgb(0 0 0 / 0.5);
                z-index: 801;
              }
            }
    
            .SeverityText {
              color: #C1272D;
              font-size: 24px;
              font-weight: bold;
              padding-left: 20px;
            }
          }
        }

        .InfoDetailBoxFiveGreen {
          background-color: #009245;
          border-width: 1px 2px 2px 2px;
          border-radius: 0 0 20px 20px;
          color: #ffffff;
          display: flex;
          position: relative;
          flex-direction: column;
          align-items: stretch;
          width: 100%;
          min-height: 40px;
          margin: 0;
          padding: 10px;
          border-style: solid;
          border-color: #cccccc;
          transition: all ease 0.5s;
  
          .MessageContainer {
            display: flex;
            align-items: center;
            position: relative;
  
            span {
              font-size: 48px;
            }
  
            .MessageText {
              padding-left: 10px;
            }
          }
        }
  
        .InfoDetailBoxFiveRed {
          background-color: #C1272D;
          border-width: 1px 2px 2px 2px;
          border-radius: 0 0 20px 20px;
          color: #ffffff;
          display: flex;
          position: relative;
          flex-direction: column;
          align-items: stretch;
          width: 100%;
          min-height: 40px;
          margin: 0;
          padding: 10px;
          border-style: solid;
          border-color: #cccccc;
          transition: all ease 0.5s;
  
          .MessageContainer {
            display: flex;
            align-items: center;
            position: relative;
  
            span {
              font-size: 48px;
            }
  
            .MessageText {
              padding-left: 10px;
            }
          }
        }
      }
  
      .ExplanationBox {
        text-align: left;
        border-top: 2px dotted #cccccc;
        border-bottom: 2px dotted #cccccc;
        margin-top: 0px;
        padding-bottom: 20px;

        h2 {
          font-size: 18px;
        }
      }
  
      .ReportChartContainer {
        display: grid;
        grid-template-columns: 100%;
  
        .ContentLeft {
          margin-right: 0;
          margin-bottom: 15px;
        }
  
        .ContentRight {
          text-align: left;
  
          .FurtherDetailBox {
            .Subtitle {
              font-weight: bold;
              margin: 10px 0;
            }
  
            .FurtherInformation {
              display: grid;
              grid-template-columns: 50% 50%;
              color: blue;
            }
          }
        }
      }
    }
  }
}